<template>
  <div style="background-color:#ddd;height:100vh">
    <div class="a">
      <div style="width:15%;display:flex">
        <div
          style="display:flex;alignItems:center;margin:auto"
          @click="$router.push('index')"
        >
          <img src="../assets/back.png" alt style="width:30px;height:30px" />
          <div style="color:#000;fontSize:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:70%;align-items: center;">
        <el-input
          placeholder="请输入客户名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="width:600px;margin:auto"
          @input="select1(search)"
        ></el-input>
        <div style="width: 100px;color: #fff;display: flex;">
          <div style="width: 20px;height: 20px;background-color:rgb(197,74,74);"></div>
          待分拣
        </div>
        <div style="width: 100px;color: #fff;display: flex;">
          <div style="width: 20px;height: 20px;background-color:rgb(188,188,0);"></div>
          部分分拣
        </div>
        <div style="width: 100px;color: #fff;display: flex;">
          <div style="width: 20px;height: 20px;background-color:rgb(66,180,133);"></div>
          已分拣
        </div>
      </div>
      <div
        style="display:flex;width:30%;margin-left:10px;flex-flow: row-reverse;"
      >
        <div class="b" style="backgroundColor:rgb(197,74,74);fontSize:15px">
          <div class="yjfj" @click="yjfj()" style="fontSize:20px">一键分拣</div>
        </div>
        <div class="b" style="backgroundColor:rgb(66,151,178);">
          <div class="sx" @click="sx" style="fontSize:20px">筛选</div>
        </div>
        <div class="b" style="backgroundColor:rgb(66,180,133);fontSize:15px">
          <div class="yjdy" @click="yjdy()" style="fontSize:20px">一键打印</div>
        </div>
      </div>
    </div>
    <div
      style="display:flex;flex-direction:row;justify-content:space-between;margin:30px 10px 10px 10px;fontSize:20px"
    >
      <div>请选择客户</div>
      <div style="color:rgb(197,74,74);">发货日期：{{ shipmentsDate }}</div>
    </div>
    <div>
      <div
        style="display:flex;flex-direciton:row;flex-wrap:wrap;background-color: #ddd;"
      >
        <div
          v-for="(item1, index1) in searchData1"
          :key="index1"
          style="margin:10px"
        >
          <div
            @click="
              goToAkhfjDetail(
                item1.clientId,
                shipmentsDate,
                item1.commodityName,
                item1.clientName
              )
            "
          >
            <div style="width:130px;height:90px;border:1px solid grey">
              <div
                style="width:40%;height:30%;backgroundColor:rgb(66,180,133);display:flex;"
                v-if="item1.numberSorted == item1.unselectedNumber"
              >
                <div style="margin:auto;color:#000;fontSize:10px">已完成</div>
              </div>
              <div
                style="width:40%;height:30%;backgroundColor:rgb(197,74,74);display:flex;"
                v-if="item1.numberSorted == 0"
              >
                <div style="margin:auto;color:#000;fontSize:10px">未分拣</div>
              </div>
              <div
                style="width:40%;height:30%;backgroundColor:rgb(188,188,0);display:flex;"
                v-if="
                  item1.numberSorted * 1 > 0 &&
                    item1.unselectedNumber * 1 > item1.numberSorted * 1
                "
              >
                <div style="margin:auto;color:#000;fontSize:10px">
                  部分分拣
                </div>
              </div>
              <div style="width:100%;height:40%;display:flex;">
                <div style="margin:auto;fontSize:12px">
                  {{ item1.clientName }}
                </div>
              </div>
              <div
                style="width:100%;height:30%;backgroundColor:rgb(66,180,133);display:flex;"
                v-if="item1.numberSorted == item1.unselectedNumber"
              >
                <div style="margin:auto;color:#000;fontSize:15px">
                  分拣进度:{{ item1.numberSorted }}/{{ item1.unselectedNumber }}
                </div>
              </div>
              <div
                style="width:100%;height:30%;backgroundColor:rgb(197,74,74);display:flex;"
                v-if="item1.numberSorted == 0"
              >
                <div style="margin:auto;color:#000;fontSize:15px">
                  分拣进度:{{ item1.numberSorted }}/{{ item1.unselectedNumber }}
                </div>
              </div>
              <div
                style="width:100%;height:30%;backgroundColor:rgb(188,188,0);display:flex;"
                v-if="
                  item1.numberSorted * 1 > 0 &&
                    item1.numberSorted * 1 < item1.unselectedNumber * 1
                "
              >
                <div style="margin:auto;color:#000;fontSize:15px">
                  分拣进度:{{ item1.numberSorted }}/{{ item1.unselectedNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-drawer
        title="筛选"
        :visible.sync="drawer"
        :direction="direction"
        size="55%"
      >
        <div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">发货日期</div>
            <div style="display:flex;flex-direction:row">
              <div
                style="width:120px;height:40px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in dateData"
                :key="index"
                @click="fhrqdj(index)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="dateIndex == index"
                >
                  {{ item }}
                </div>
                <div style="margin:auto" v-else>{{ item }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="dateIndex == index"
                />
              </div>
              <div style="width:120px;height:60px;margin:10px">
                <el-date-picker
                  v-model="shipmentsDate"
                  type="date"
                  placeholder="选择日期"
                  style
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div style="display:flex;flex-direction:row">
            <div style="display:flex">
              <div style="margin:10px 0 0 10px;fontWeight:bold">分拣状态</div>
              <div style="display:flex;flex-direction:row">
                <el-select v-model="fjztValue" placeholder="请选择">
                  <el-option
                    v-for="item in fjztData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item, index) in fjztData"
                  :key="index"
                  @click="fjztdj(index)"
                >
                  <div
                    style="margin:auto;color:rgb(66,151,178)"
                    v-if="fjztIndex == index"
                  >
                    {{ item }}
                  </div>
                  <div style="margin:auto" v-else>{{ item }}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="fjztIndex == index"
                  />
                </div> -->
              </div>
            </div>
            <div style="display:flex">
              <div style="margin:10px 0 0 10px;fontWeight:bold">是否标品</div>
              <div style="display:flex;flex-direction:row">
                <el-select v-model="sfbpValue" placeholder="请选择">
                  <el-option
                    v-for="item in sfbpData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item, index) in sfbpData"
                  :key="index"
                  @click="sfbpdj(index)"
                >
                  <div
                    style="margin:auto;color:rgb(66,151,178)"
                    v-if="sfbpIndex == index"
                  >
                    {{ item }}
                  </div>
                  <div style="margin:auto" v-else>{{ item }}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="sfbpIndex == index"
                  />
                </div> -->
              </div>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">更多筛选条件</div>
            <div style="margin:10px">
              <el-select
                v-model="warehouseValue"
                placeholder="请选择仓库"
                style="margin-left:10px"
              >
                <el-option
                  v-for="item in warehouseData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-model="shsjValue"
                placeholder="请选择收货时间"
                style="margin-left:10px"
              >
                <el-option
                  v-for="item in shsjData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">请选择路线</div>
            <div style="marginLeft:20px;marginTop:10px">
              <el-select v-model="path" placeholder="请选择">
                <el-option
                  v-for="item in lxData"
                  :key="item.lineId"
                  :label="item.lineName"
                  :value="item.lineId"
                >
                </el-option>
              </el-select>
              <!-- <div
                style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in lxData"
                :key="index"
                @click="lxdj(index, item.lineId)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="lxIndex.indexOf(index) != -1"
                >
                  {{ item.lineName }}
                </div>
                <div style="margin:auto" v-else>{{ item.lineName }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="lxIndex.indexOf(index) != -1"
                />
              </div> -->
            </div>
          </div>
          <div style="display:flex;flex-direcion:row;justify-content:flex-end;">
            <div
              style="width:250px;height:60px;display:flex;border:1px solid black;margin-right:20px"
              @click="() => (drawer = false)"
            >
              <div style="margin:auto">取消</div>
            </div>
            <div
              @click="
                select();
                drawer = false;
              "
              style="width:250px;height:60px;display:flex;background-color:rgb(66,151,178);margin-right:20px"
            >
              <div style="margin:auto;color:#000">
                确定
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span style="fontSize:20px">是否确定一键打印{{ num }}个标签</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="print()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible1" width="30%">
        <span
          >将有
          {{ num }}
          个标签被打印（注意：此功能会分拣并打印所有未分拣的商品），确定全部打印？</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="yjfj(1)">是</el-button>
          <el-button @click="dialogVisible1 = false">否</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../apis/aspfj/aspfj";
export default {
  data() {
    return {
      num: 0,
      value: "",
      options: [],
      drawer: false,
      direction: "ttb",
      search: "",
      radio: "1",
      radioData: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "肉类新月",
        },
      ],
      shouqi: "— 收起",
      shouqiflag: true,
      dialogVisible: false,
      dateData: ["昨日", "今日", "明日"],
      dateIndex: 0,
      shipmentsDate: "",
      fjztData: [
        { label: "全部", value: "" },
        { label: "已分拣", value: 1 },
        { label: "未分拣", value: 0 },
      ],
      fjztIndex: 0,
      fjztValue: "",
      sfbpData: [
        { label: "全部", value: "" },
        { label: "标品", value: 1 },
        { label: "未标品", value: 0 },
      ],
      sfbpIndex: 0,
      sfbpValue: "",
      lxData: [{ lineName: "全部", lineId: "" }],
      lxIndex: [],
      lxValue: [],
      lineId: "",
      gysData: [{ providerName: "全部供应商", providerId: "" }],
      gysIndex: 0,
      dialogVisible1: false,
      searchData: [],
      czStyle1:
        "width:300px;height:200px;background-color:rgb(66,180,133);border:1px solid grey;",
      czStyle2:
        "width:300px;height:200px;background-color:rgb(197,74,74);border:1px solid grey;",
      warehouseData: [],
      warehouseValue: "",
      shsjData: [],
      shsjValue: "",
      searchData1: [],
      shipmentsDate: "",
      path: "",
      printData: [],
      template: {},
    };
  },
  beforeRouteLeave(to, from, next) {
    // 离开当前路由页面时调用
    this.drawer = false;
    next();
  },
  beforeRouteEnter(to, from, next) {
    // 注意，在路由进入之前，组件实例还未渲染，所以无法获取this实例，只能通过vm来访问组件实例
    next((vm) => {
      vm.shipmentsDate = localStorage.shipmentsDate;
      vm.select();
    });
  },
  methods: {
    print() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.printData.forEach((val) => {
        PrintAccount(this.template, [], val);
      });
    },
    select1(search) {
      this.select();
    },
    select() {
      api
        .select(
          localStorage.identity,
          this.search,
          this.shipmentsDate,
          this.fjztValue,
          this.sfbpValue,
          this.shsjValue,
          this.warehouseValue,
          this.path
        )
        .then((res) => {
          console.log(res);
          this.searchData1 = res.data;
        });
    },
    // 查询送货时间接口
    selectDeliveryTime() {
      api.selectDeliveryTime().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.shsjData.push({
            value: item.deliveryTimeId,
            label: item.deliveryTime,
          });
        });
      });
    },
    // 查询供应商接口
    queryProvider() {
      api.queryProvider().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.gysData.push(item);
        });
      });
    },
    // 查询线路接口
    selectDeliveryLine() {
      api.selectDeliveryLine().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.lxData.push(item);
        });
      });
    },
    // 查询仓库接口
    selectWarehouse() {
      api.selectWarehouse().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.warehouseData.push({
            value: item.warehouseId,
            label: item.warehouseName,
          });
        });
      });
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    fhrqdj(e) {
      this.dateIndex = e;
      if (e == 0) {
        this.shipmentsDate = this.getDay(0, "-");
      }
      if (e == 1) {
        this.shipmentsDate = this.getDay(1, "-");
      }
      if (e == 2) {
        this.shipmentsDate = this.getDay(2, "-");
      }
      this.select();
    },
    fjztdj(e) {
      this.fjztIndex = e;
      if (this.fjztIndex == 0) {
        this.fjztValue = "";
      }
      if (this.fjztIndex == 1) {
        this.fjztValue = 1;
      }
      if (this.fjztIndex == 2) {
        this.fjztValue = 0;
      }
    },
    sfbpdj(e) {
      this.sfbpIndex = e;
      if (this.sfbpIndex == 0) {
        this.sfbpValue = "";
      }
      if (this.sfbpIndex == 1) {
        this.sfbpValue = 1;
      }
      if (this.sfbpIndex == 2) {
        this.sfbpValue = 0;
      }
    },
    lxdj(e, e1) {
      // console.log(e)
      if (this.lxIndex.indexOf(e) == -1) {
        this.lxIndex.push(e);
        this.lxValue.push(e1);
        this.lineId = this.lxValue.join(",");
      } else {
        this.lxIndex.splice(this.lxIndex.indexOf(e), 1);
        this.lxValue.splice(this.lxValue.indexOf(e1), 1);
        this.lineId = this.lxValue.join(",");
      }
      console.log(this.lxIndex);
    },
    gysdj(e) {
      this.gysIndex = e;
    },
    shouqiweishouqi() {
      if (this.shouqi == "+ 展开") {
        this.shouqi = "— 收起";
        this.shouqiflag = true;
      } else {
        this.shouqi = "+ 展开";
        this.shouqiflag = false;
      }
    },
    yjdy(item) {
      api
        .print(
          localStorage.identity,
          this.shipmentsDate,
          this.firstTypeId,
          this.secondTypeId
        )
        .then((res) => {
          if (!item) this.dialogVisible = true;
          this.num = res.data.length;
          this.printData = res.data;
          this.template = res.data2;
          console.log(res);
          // if (!item) {
          //   this.$message({ type: "success", message: "打印成功" });
          //   res.data.forEach((val) => {
          //     PrintAccount(res.data2, [], val);
          //   });
          // }
        });
    },
    sx() {
      this.drawer = true;
    },
    yjfj(isAllow) {
      if (!isAllow) {
        api
          .oneKeyPrint({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: this.firstTypeId,
            secondTypeId: null,
            keyword: this.search,
            commodityId: null,
            commodityMetering: null,
          })
          .then((res) => {
            console.log(res);
            this.dialogVisible1 = true;
            this.num = res.data.length;
            this.printData = res.data;
            this.template = res.data2;
          });
      } else {
        if (this.num == 0) {
          this.$message.error("暂无可分拣商品");
          return;
        }
        api
          .sortingAllGoods({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: this.firstTypeId,
            secondTypeId: null,
            keyword: this.search,
            commodityId: null,
            commodityMetering: null,
          })
          .then((res) => {
            if (res.state == 200) {
              this.$message({ type: "success", message: "分拣成功" });
              this.print();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    goToAkhfjDetail(e1, e2, e3, e4) {
      this.$router.push(
        "/akhfjDetail?clientId=" +
          e1 +
          "&shipmentsDate=" +
          this.shipmentsDate +
          "&commodityName=" +
          e3 +
          "&clientName=" +
          e4
      );
    },
  },
  mounted() {
    this.shipmentsDate = localStorage.shipmentsDate;
    // this.shipmentsDate = this.getDay(0, "-");
    this.shipmentsDate = localStorage.shipmentsDate;
    this.selectWarehouse();
    this.selectDeliveryLine();
    this.queryProvider();
    this.selectDeliveryTime();
    this.select();
  },
};
</script>

<style scoped>
.a {
  width: 100%;
  height: calc(10vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: #000;
}
.c {
  width: 15%;
  height: calc(95vh);
  background-color: rgb(39, 37, 37);
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: #000;
}
::v-deep .el-drawer__header {
  display: none;
}
</style>
