<template>
  <div>
    <div class="haha" v-loading="loading">
      <img
        src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.vjshi.com%2F2019-04-23%2F43b4675aea47b0d57d9404f03aefa7bf%2F00003.jpg%3Fx-oss-process%3Dstyle%2Fwatermark&refer=http%3A%2F%2Fpic.vjshi.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638411455&t=0ee16f0d388868823fc49328ee413222"
        alt
        style="width:100%;height:calc(100vh);position:relative"
      />
      <div style="color:white;position:absolute;top:50px;width:100%;display:flex;fontSize:30px">
        <div style="margin:auto">Windows端分拣系统</div>
      </div>
      <div style="color:white;position:absolute;top:50px;right:50px;display:flex;fontSize:20px;">
        <div @click="close1" style="display:flex;flex-direction:row;alignItems:center">
          <img src="../assets/guanbi.png" alt style="wifth:20px;height:20px" />
          <div style="margin-left:10px">关闭</div>
        </div>
      </div>
      <div style="width:100%;height:450px;position:absolute;top:150px;display:flex">
        <div class="a">
          <div class="b">
            <img src="../assets/yonghuming.png" alt style="width:35px;height:35px;margin-left:10px" />
            <el-input v-model="username" placeholder="请输入用户名"></el-input>
          </div>
          <div class="b">
            <img src="../assets/mima.png" alt style="width:35px;height:35px;margin-left:10px" />
            <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
          </div>
          <div class="c">
            <div style="color:white;margin-right:10px;fontSize:20px">发货日期</div>
            <el-date-picker
              v-model="shipmentsDate"
              type="date"
              placeholder="选择日期"
              style="width:275px;fontSize:10px;"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <div class="day" @click="jintian">
              <div style="margin:auto;fontSize:15px">今天</div>
            </div>
            <div class="day" @click="mingtian">
              <div style="margin:auto;fontSize:15px">明天</div>
            </div>
          </div>
          <div class="d">
            <el-checkbox v-model="checked" style="color:white">记住密码</el-checkbox>
            <div class="shezhi" @click="shezhi">
              <img src="../assets/shezhi.png" alt style="width:20px;height:20px" />
              <div>设置</div>
            </div>
          </div>
          <div class="e" @click="login">
            <div style="margin:auto;color:white;fontSize:20px">登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import api from "../apis/login/login";
//引入请求拦截器
import service from "../kit/axios_config";
export default {
  data() {
    return {
      username: "",
      password: "",
      shipmentsDate: "",
      checked: true,
      loading: false
    };
  },
  watch: {
    //监听保存框
    checked() {
      if (!this.checked) {
        //取消保存后清空本地存储的密码和用户名
        localStorage.clear();
      }
    },
    $route(to, from) {
      this.$router.go(0);
    }
  },
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next(0);
  },
  methods: {
    close1(){
      console.log(11)
      window.close()
    },
    shezhi() {
      this.$alert("该功能尚未开放", {
        confirmButtonText: "确定"
      });
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    jintian() {
      this.shipmentsDate = this.getDay(1, "-");
    },
    mingtian() {
      this.shipmentsDate = this.getDay(2, "-");
    },
    login() {
      console.log("登录")
      api
        .login({
          username: this.username,
          password: this.password
        })
        .then(res => {
          console.log(res);
          this.loading = true;
          if (res.state == 200) {
            //是否保存用户名密码
            //选中保存框保存用户名和密码到本地
            if (this.checked) {
              localStorage.username = this.username;
              localStorage.password = this.password;
              localStorage.identity = res.data.identity;
              localStorage.shipmentsDate = this.shipmentsDate;
              //将用户信息遍历存入会话存储
              for (var key in res.data) {
                sessionStorage[key] = res.data[key];
              }
              //设置请求头token
              service.defaults.headers.token = res.data2;
              //存储token到会话存储
              sessionStorage.token = res.data2;
              this.$message({
                message: "登陆成功",
                type: "success"
              });
              this.loading = false;
              this.$router.push("/index?checked=1");
            } else {
              localStorage.username = this.username;
              localStorage.password = this.password;
              localStorage.identity = res.data.identity;
              localStorage.shipmentsDate = this.shipmentsDate;
              //将用户信息遍历存入会话存储
              for (var key in res.data) {
                sessionStorage[key] = res.data[key];
              }
              //设置请求头token
              service.defaults.headers.token = res.data2;
              //存储token到会话存储
              sessionStorage.token = res.data2;
              this.$message({
                message: "登陆成功",
                type: "success"
              });
              this.loading = false;
              this.$router.push("/index?checked=2");
            }
          } else {
            this.$message({ message: res.message, type: "error" });
            this.loading = false;
            return;
          }
        });
      // this.$router.push("/index");
    }
  },
  mounted() {
    //获取本地用户名密码
    this.username = localStorage.username
      ? localStorage.getItem("username")
      : "";
    this.password = localStorage.password
      ? localStorage.getItem("password")
      : "";
    this.checked = localStorage.username ? true : false;
    this.shipmentsDate = this.getDay(1, "-");
  }
};
</script>

<style scoped>
.haha {
  width: 100%;
  height: calc(100vh);
}
.a {
  background-color: rgb(27, 52, 80);
  width: 70%;
  height: 450px;
  margin: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  opacity: 0.8;
}
.b {
  width: 80%;
  height: 60px;
  display: flex;
  background-color: white;
  margin: auto;
  align-items: center;
  border-radius: 5px;
}
::v-deep .el-input__inner {
  height: 50px;
  border: 1px solid white;
  font-size: 20px;
}
.day {
  width: 70px;
  height: 50px;
  background-color: white;
  margin-left: 10px;
  display: flex;
}
.c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 80%;
  align-items: center;
}
.d {
  width: 80%;
  height: 60px;
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
::v-deep .el-checkbox__label {
  font-size: 20px;
}
::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.shezhi {
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 20px;
  align-items: center;
}
.shezhi > :first-child {
  margin-right: 10px;
}
.e {
  width: 80%;
  height: 50px;
  display: flex;
  background-color: #ff4b4b;
  margin: auto;
  align-items: center;
  border-radius: 5px;
}
::v-deep .el-input__inner:focus {
  border-color: white;
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 5px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
</style>
