import axios from "../../kit/axios_config";
import qs from "qs";

export default {
  // 查询单位
  getUnits(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityMetering", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  //一键分拣
  sortingAllGoods(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/onePieceSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 打印
  print(
    identity,
    shipmentsDate,
    firstTypeId,
    secondTypeId,
    commodityMetering,
    commodityId,
    orderCommodityId,
    num
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectPrintInfo", {
          params: {
            identity,
            shipmentsDate,
            firstTypeId,
            secondTypeId,
            commodityMetering,
            commodityId,
            orderCommodityId,
            num,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询按客户分拣客户列表接口
  select(
    identity,
    clientName,
    shipmentsDate,
    sortingState,
    isRough,
    deliveryTimeId,
    warehouseId,
    lineId
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCustomer/select", {
          params: {
            identity,
            clientName,
            shipmentsDate,
            sortingState,
            isRough,
            deliveryTimeId,
            warehouseId,
            lineId,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询指定客户下的分拣商品列表接口
  selectSortingList(identity, clientId, shipmentsDate, commodityName, sort) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCustomer/selectSortingList", {
          params: { identity, clientId, shipmentsDate, commodityName, sort },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击操作分拣商品接口查询接口
  viewDetails(orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/viewDetails", { params: { orderCommodityId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 重置商品分拣接口
  resetSorting(orderCommodityId, orderId, commodityId, sortingUnits) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/resetSorting", {
          params: { orderCommodityId, orderId, commodityId, sortingUnits },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 标记缺货接口
  signOutStock(orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/signOutStock", { params: { orderCommodityId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 多次分拣接口
  manyTimesSorting(orderCommodityId, sortingRecord, thisNum, orderNum, type) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/manyTimesSorting", {
          params: {
            orderCommodityId,
            sortingRecord,
            thisNum,
            orderNum,
            type,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 一键分拣打印数据
  oneKeyPrint(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/printOnePieceSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 分拣商品接口
  sortingCommodity(orderCommodityId, sortingUnits, orderNum, practicalNum) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/sortingCommodity", {
          params: {
            orderCommodityId,
            sortingUnits,
            orderNum,
            practicalNum,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询送货时间接口
  selectDeliveryTime() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryTime").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询仓库接口
  selectWarehouse() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectWarehouse").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询线路接口
  selectDeliveryLine() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryLine").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询供应商接口
  queryProvider() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/queryProvider").then((res) => {
        resolve(res.data);
      });
    });
  },
};
