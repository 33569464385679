import axios from "../../kit/axios_config";
import qs from 'qs'

export default {
    // 登录
    login(data) {
        return new Promise(resolve => {
            axios.post("/sortingAppLogin/login", qs.stringify(data), ).then(res => {
                resolve(res.data)
            })
        })
    },
};
