import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Aspfj from '../views/Aspfj.vue'
import AspfjDetail from '../views/AspfjDetail.vue'
import Akhfj from '../views/Akhfj.vue'
import Qhsp from '../views/Qhsp.vue'
import AkhfjDetail from '../views/AkhfjDetail.vue'
import Ddfjcy from '../views/Ddfjcy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      keepAlive:true
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/aspfj',
    name: 'Aspfj',
    component: Aspfj,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/aspfjDetail',
    name: 'AspfjDetail',
    component: AspfjDetail,
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/akhfj',
    name: 'Akhfj',
    component: Akhfj,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/qhsp',
    name: 'Qhsp',
    component: Qhsp,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/akhfjDetail',
    name: 'AkhfjDetail',
    component: AkhfjDetail,
    meta:{
      keepAlive:false
    }
  },
  {
    path: '/ddfjcy',
    name: 'ddfjcy',
    component: Ddfjcy,
    meta:{
      keepAlive:true
    }
  },
]

const router = new VueRouter({
  // mode: "history",
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
