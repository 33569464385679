<template>
  <div>
    <div class="a">
      <div style="width:7%;display:flex">
        <div style="display:flex;alignItems:center;margin:auto" @click="$router.push('index')">
          <img src="../assets/back.png" alt style="width:30px;height:30px" />
          <div style="color:white;fontSize:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:77%">
        <el-input
          placeholder="请输入商品名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="width:900;margin:auto"
          @input="select(search)"
        ></el-input>
      </div>
      <div style="display:flex;width:16%;margin-left:10px;flex-flow: row-reverse;">
        >
        <div class="b" style="backgroundColor:rgb(18, 73, 157);">
          <div class="sx" @click="xzkz">选择筐重</div>
        </div>
        <div class="b" style="backgroundColor:rgb(66,151,178);">
          <div class="sx" @click="sx">筛选</div>
        </div>
      </div>
    </div>
    <div>
      <div style="height:200px;display:flex;flex-direciton:row; flex-flow: row wrap">
        <div v-for="(item,index) in searchData" :key="index" style="margin:7px;">
          <div style="width:300px;height:200px;background-color:white;border:1px solid grey;">
            <div style="display:flex;flex-direciton:row;justify-content:space-between">
              <div style="width:85%;margin:auto;color:black">
                <div style="height:20px;margin:auto;display:flex;fontSize:10px">
                  <div style="margin:auto">
                    <span>{{item.commodityName}}</span>
                  </div>
                </div>
                <div style="height:30px;margin:auto;display:flex;fontSize:10px">
                  <div style="margin:auto">
                    <span>[{{item.clientNo}}]</span>
                    <span style="margin:5px">{{item.clientName}}</span>
                  </div>
                </div>
                <div style="height:70px;margin:auto;display:flex;">
                  <div style="margin:auto;fontSize:25px">
                    <div>{{item.orderNum}}{{item.commodityMetering}}</div>
                  </div>
                </div>
                <div style="height:70px;margin:auto;display:flex;">
                  <div style="margin:auto">
                    <div>称重：{{item.practicalNum == null ? 0 : item.practicalNum}}{{item.commodityMetering}}</div>
                  </div>
                </div>
              </div>
              <div
                style="width:15%;margin:auto;display:flex;;background-color:white;height:200px;text-align: center;border-left:1px solid grey"
              >
                <div style="margin:auto;fontSize:25px">
                  <div
                    @click="cz(item.orderNum,item.practicalNum,item.clientNo,item.clientName,item.sortingId,item.isStockout,item.commodityName)"
                  >操作</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-drawer title="筛选" :visible.sync="drawer" :direction="direction" size="70%">
        <div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">发货日期</div>
            <div style="display:flex;flex-direction:row">
              <div
                style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item,index) in dateData"
                :key="index"
                @click="fhrqdj(index)"
              >
                <div style="margin:auto;color:rgb(66,151,178)" v-if="dateIndex == index">{{item}}</div>
                <div style="margin:auto" v-else>{{item}}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="dateIndex == index"
                />
              </div>
              <div style="width:120px;height:60px;margin:10px">
                <el-date-picker
                  v-model="date"
                  type="date"
                  placeholder="选择日期"
                  style
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div style="display:flex;flex-direction:row">
            <div>
              <div style="margin:10px 0 0 10px;fontWeight:bold">分拣状态</div>
              <div style="display:flex;flex-direction:row">
                <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item,index) in fjztData"
                  :key="index"
                  @click="fjztdj(index)"
                >
                  <div style="margin:auto;color:rgb(66,151,178)" v-if="fjztIndex == index">{{item}}</div>
                  <div style="margin:auto" v-else>{{item}}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="fjztIndex == index"
                  />
                </div>
              </div>
            </div>
            <div>
              <div style="margin:10px 0 0 10px;fontWeight:bold">是否标品</div>
              <div style="display:flex;flex-direction:row">
                <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item,index) in sfbpData"
                  :key="index"
                  @click="sfbpdj(index)"
                >
                  <div style="margin:auto;color:rgb(66,151,178)" v-if="sfbpIndex == index">{{item}}</div>
                  <div style="margin:auto" v-else>{{item}}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="sfbpIndex == index"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">更多筛选条件</div>
            <div style="margin:10px">
              <el-select v-model="shsjValue" placeholder="请选择收货时间">
                <el-option
                  v-for="item in shsjData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select v-model="warehouseValue" placeholder="请选择仓库" style="margin-left:10px">
                <el-option
                  v-for="item in warehouseData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- <el-select v-model="value" placeholder="请选择" style="margin-left:10px">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>-->
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">请选择路线</div>
            <div style="display:flex;flex-direction:row">
              <div
                style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item,index) in lxData"
                :key="index"
                @click="lxdj(index,item.lineId)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="lxIndex.indexOf(index) != -1"
                >{{item.lineName}}</div>
                <div style="margin:auto" v-else>{{item.lineName}}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="lxIndex.indexOf(index) != -1"
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">请选择供应商</div>
            <div style="display:flex;flex-direction:row">
              <div
                style="width:150px;height:300px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item,index) in gysData"
                :key="index"
                @click="gysdj(index,item.providerId)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="gysIndex == index"
                >{{item.providerName}}</div>
                <div style="margin:auto" v-else>{{item.providerName}}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:20px;height:20px;position:absolute;bottom:0;right:0"
                  v-if="gysIndex == index"
                />
              </div>
            </div>
          </div>
          <div style="display:flex;flex-direcion:row;justify-content:flex-end;">
            <div
              style="width:250px;height:60px;display:flex;border:1px solid black;margin-right:20px"
              @click="()=>(drawer = false)"
            >
              <div style="margin:auto">取消</div>
            </div>
            <div
              style="width:300px;height:60px;display:flex;background-color:rgb(66,151,178);margin-right:20px"
            >
              <div style="margin:auto;color:white">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-drawer :visible.sync="drawer1" :direction="direction" size="40%">
        <div>
          <div>
            <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <div
                style="width:200px;height:80px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item,index) in kzData"
                :key="index"
              >
                <div v-if="item.item1 != '不设置称重筐' && kzIndex == index" @click="kzdj(index)">
                  <div style="display:flex;flex-direction:row;width:200px;height:50%">
                    <span style="margin:auto">{{item.item1 + " " + item.item2}}</span>
                  </div>
                  <div style="display:flex;flex-direction:row;width:200px;height:50%">
                    <div style="margin:auto">
                      <span @click="jian(index,item.value)">-</span>
                      <span style="margin:0 20px 0 20px">{{item.value}}</span>
                      <span @click="jia(index,item.value)">+</span>
                    </div>
                  </div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="kzIndex == index"
                  />
                </div>
                <div v-if="item.item1 == '不设置称重筐'" @click="kzdj(index)">
                  <div style="display:flex;flex-direction:row;width:200px;height:100%">
                    <span style="margin:auto">{{item.item1}}</span>
                  </div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="kzIndex == index"
                  />
                </div>
                <div v-if="item.item1 != '不设置称重筐' && kzIndex != index" @click="kzdj(index)">
                  <div style="display:flex;flex-direction:row;width:200px;height:100%">
                    <span style="margin:auto">{{item.item1 + " " + item.item2}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="display:flex;flex-direction:row;justify-content:space-around;height:50%;margin-top:10px"
          >
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;border:1px solid black"
              @click="drawer1 = false"
            >
              <div style="margin:auto;fontSize:20px">取消</div>
            </div>
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;backgroundColor:rgb(66,151,178);color:white"
              @click="drawer1 = false"
            >
              <div style="margin:auto;fontSize:20px">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-dialog
        :visible.sync="dialogVisible2"
        width="45%"
        :title="commodityName + ' [ ' + clientNo + ' - ' + clientName + ' ] '"
      >
        <div style="width:100%;display:flex;flex-direction:row;">
          <div style="width:50%;display:flex;flex-direction:row;height:60px">
            <div :style="fjStyle" @click="fj(0)">
              <div style="margin:auto;fontSize:20px">分拣</div>
            </div>
            <div :style="fjStyle1" @click="fj(1)" v-if="!fenjianFlag">
              <div style="margin:auto;fontSize:20px">多次分拣</div>
            </div>
            <!-- <div :style="fjStyle2" @click="fj(2)" v-if="!fenjianFlag">
              <div style="margin:auto;fontSize:20px">定值分拣</div>
            </div>-->
          </div>
          <div style="display:flex;flex-direction:row;">
            <div
              style="width:100px;display:flex;backgroundColor:rgb(66,180,133);color:white;margin-left:10px"
              @click="reset()"
              v-if="viewDetailsData.sortingRecord!=0 || isStockout == 1"
            >
              <div style="margin:auto;fontSize:30px">重置</div>
            </div>
            <div
              style="width:100px;display:flex;backgroundColor:rgb(66,151,178);color:white;margin-left:10px"
            >
              <div style="margin:auto;fontSize:30px">打印</div>
            </div>
            <div
              style="width:150px;display:flex;backgroundColor:orange;color:white;margin-left:10px"
              v-if="!fenjianFlag && isStockout != 1"
              @click="bjqh()"
            >
              <div style="margin:auto;fontSize:30px">标记缺货</div>
            </div>
          </div>
        </div>
        <div style="margin:20px 0 20px 0">
          <span style="fontSize:20px">订购数量：</span>
          <span
            style="fontSize:20px;color:rgb(197,74,74)"
          >{{viewDetailsData.orderNum}}{{viewDetailsData.commodityMetering}}</span>
        </div>
        <el-input v-model="input" :disabled="inputFlag" v-if="fjIndex != 1"></el-input>
        <el-input v-model="input1" v-if="fjIndex == 1"></el-input>
        <div v-if="fjIndex == 0 && !fenjianFlag">
          <div style="margin-top:20px">
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(1)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">1</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(2)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">2</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(3)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">3</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('删除')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">删除</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(4)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">4</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(5)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">5</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(6)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">6</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('清空')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">清空</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(7)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">7</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(8)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">8</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(9)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">9</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('.')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">.</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('返回')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">返回</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(0)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">0</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:50%;border:1px solid black"
                @click="anniu('完成')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">完成</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="fjIndex == 1">
          <div
            style="display:flex;flex-direction:row;justify-content:space-between;margin:20px 0 20px 0;fontSize:20px"
          >
            <div>
              <span>分拣记录：</span>
              <span>{{viewDetailsData.sortingRecord}}</span>
            </div>
            <div style="display:flex;flex-direction:row">
              <div>
                <span>分拣总和：</span>
                <span>{{viewDetailsData.sortingRecord}}{{viewDetailsData.commodityMetering}}</span>
              </div>
              <div style="margin-left:20px">
                <span>未分拣量：</span>
                <span>{{viewDetailsData.unsortedQuantity}}{{viewDetailsData.commodityMetering}}</span>
              </div>
            </div>
          </div>
          <div style="margin-top:20px">
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(1)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">1</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(2)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">2</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(3)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">3</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('删除')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">删除</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(4)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">4</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(5)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">5</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(6)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">6</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('清空')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">清空</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(7)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">7</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(8)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">8</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(9)"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">9</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('.')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">.</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('返回')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">返回</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('0')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">0</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('记录称重')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">记录称重</div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('完成')"
              >
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">完成</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="fjIndex == 2">
          <div style="display:flex;flex-direction:row;alignItems:center">
            <el-input v-model="input" style="width:80%"></el-input>
            <span style="fontSize:20px;;margin-left:20px">袋</span>
          </div>
          <div style="display:flex;flex-direction:row;alignItems:center;margin-top:20px">
            <el-input v-model="input" style="width:80%"></el-input>
            <span style="fontSize:20px;;margin-left:20px">份</span>
          </div>
          <div style="margin-top:20px">
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">1</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">2</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">3</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">删除</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">4</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">5</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">6</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">清空</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">7</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">8</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">9</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">.</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">返回</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">0</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">记录称重</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">完成</div>
              </div>
            </div>
          </div>
        </div>-->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../apis/qhsp/qhsp";
export default {
  data() {
    return {
      drawer: false,
      drawer1: false,
      direction: "ttb",
      search: "",
      dateData: ["昨日", "今日", "明日"],
      dateIndex: 1,
      date: "",
      fjztData: ["全部", "已分拣", "未分拣"],
      fjztIndex: 0,
      fjztValue: "",
      sfbpData: ["全部", "标品", "未标品"],
      sfbpIndex: 0,
      sfbpValue: "",
      lxData: [],
      lxIndex: [],
      lxValue: [],
      lineId: "",
      gysData: [{ providerName: "全部供应商", providerId: "" }],
      gysIndex: 0,
      gysValue: "",
      warehouseData: [],
      warehouseValue: "",
      shsjData: [],
      shsjValue: "",
      shipmentsDate: "",
      kzData: [
        {
          item1: "不设置称重筐",
          item2: "",
          value: ""
        },
        {
          item1: "测试数据1",
          item2: "11",
          value: 1
        },
        {
          item1: "测试数据2",
          item2: "12",
          value: 1
        },
        {
          item1: "测试数据3",
          item2: "13",
          value: 1
        },

        {
          item1: "测试数据4",
          item2: "14",
          value: 1
        },
        {
          item1: "测试数据5",
          item2: "15",
          value: 1
        },
        {
          item1: "测试数据6",
          item2: "16",
          value: 1
        },
        {
          item1: "测试数据7",
          item2: "17",
          value: 1
        },
        {
          item1: "测试数据8",
          item2: "18",
          value: 1
        },
        {
          item1: "测试数据9",
          item2: "19",
          value: 1
        },
        {
          item1: "测试数据10",
          item2: "20",
          value: 1
        }
      ],
      kzIndex: 0,
      searchData: [],
      clientNo: "",
      clientName: "",
      viewDetailsData: [],
      fjIndex: 0,
      fjStyle: "width:33%;display:flex;backgroundColor:#E6E6FA;color:black",
      fjStyle1: "width:33%;display:flex;backgroundColor:#E6E6FA;color:black",
      fjStyle2: "width:33%;display:flex;backgroundColor:#E6E6FA;color:black",
      nofjStyle:
        "width:33%;display:flex;backgroundColor:rgb(197,74,74);color:white",
      fenjianFlag: false,
      dialogVisible2: false,
      isStockout: "",
      inputFlag: false,
      input: "",
      input1: "",
      commodityName: ""
    };
  },
  methods: {
    select(e) {
      console.log(e);
      this.selectOutOfStockCommodity();
      if (e != "") {
        this.searchFlag = false;
      } else {
        this.searchFlag = true;
      }
    },
    reset() {
      this.$confirm("重置成功后，历史称重数据将被清除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          api
            .resetSorting(
              this.viewDetailsData.sortingId,
              this.viewDetailsData.orderId,
              this.viewDetailsData.commodityId,
              this.viewDetailsData.sortingUnits
            )
            .then(res => {
              if (res.state == 200) {
                this.$message({
                  type: "success",
                  message: "重置成功!"
                });
                this.dialogVisible2 = false;
                this.date = this.getDay(-1, "-");
                this.fj(0);
                this.selectOutOfStockCommodity();
              }
            });
        })
        .catch(() => {});
    },
    anniu(e) {
      if (e == 1) {
        this.input = this.input + "1";
      }
      if (e == 2) {
        this.input = this.input + "2";
      }
      if (e == 3) {
        this.input = this.input + "3";
      }
      if (e == 4) {
        this.input = this.input + "4";
      }
      if (e == 5) {
        this.input = this.input + "5";
      }
      if (e == 6) {
        this.input = this.input + "6";
      }
      if (e == 7) {
        this.input = this.input + "7";
      }
      if (e == 8) {
        this.input = this.input + "8";
      }
      if (e == 9) {
        this.input = this.input + "9";
      }
      if (e == 0) {
        this.input = this.input + "0";
      }
      if (e == "删除") {
        this.input = this.input.toString().slice(0, this.input.length - 1);
      }
      if (e == "清空") {
        this.input = "";
      }
      if (e == ".") {
        if (
          this.input.indexOf(".") != -1 ||
          this.input == "" ||
          this.input.substr(this.input.indexOf(".")).length > 2
        ) {
          this.input = this.input;
        } else {
          this.input = this.input + ".";
        }
      }
      if (e == "完成") {
        console.log(this.input.substr(this.input.indexOf(".")).length);
        if (this.input.substr(this.input.indexOf(".")).length > 3) {
          this.$message({
            message: "只能输入两位小数",
            type: "error"
          });
          return;
        }
        api
          .sortingCommodity(
            this.sortingId,
            this.viewDetailsData.orderId,
            this.viewDetailsData.commodityId,
            this.viewDetailsData.commodityMetering,
            parseFloat(this.viewDetailsData.orderNum),
            parseFloat(this.input)
          )
          .then(res => {
            console.log(res);
            if (res.state == 200) {
              this.$message({
                message: "分拣成功",
                type: "success"
              });
              this.dialogVisible2 = false;
              this.date = this.getDay(-1, "-");
              this.fj(0);
              this.selectOutOfStockCommodity();
              this.input = "";
            }
          });
      }
    },
    anniu1(e) {
      if (e == 1) {
        this.input1 = this.input1 + "1";
      }
      if (e == 2) {
        this.input1 = this.input1 + "2";
      }
      if (e == 3) {
        this.input1 = this.input1 + "3";
      }
      if (e == 4) {
        this.input1 = this.input1 + "4";
      }
      if (e == 5) {
        this.input1 = this.input1 + "5";
      }
      if (e == 6) {
        this.input1 = this.input1 + "6";
      }
      if (e == 7) {
        this.input1 = this.input1 + "7";
      }
      if (e == 8) {
        this.input1 = this.input1 + "8";
      }
      if (e == 9) {
        this.input1 = this.input1 + "9";
      }
      if (e == 0) {
        this.input1 = this.input1 + "0";
      }
      if (e == "删除") {
        this.input1 = this.input1.toString().slice(0, this.input1.length - 1);
      }
      if (e == "清空") {
        this.input1 = "";
      }
      if (e == ".") {
        if (
          this.input1.indexOf(".") != -1 ||
          this.input1 == "" ||
          this.input1.substr(this.input1.indexOf(".")).length > 2
        ) {
          this.input1 = this.input1;
        } else {
          this.input1 = this.input1 + ".";
        }
      }
      if (e == "完成") {
        console.log(this.input1.substr(this.input1.indexOf(".")).length);
        if (this.input1.substr(this.input1.indexOf(".")).length > 3) {
          this.$message({
            message: "只能输入两位小数",
            type: "error"
          });
          return;
        }
        api
          .manyTimesSorting(
            this.sortingId,
            this.viewDetailsData.orderId,
            this.viewDetailsData.commodityId,
            this.viewDetailsData.commodityMetering,
            parseFloat(this.viewDetailsData.orderNum),
            parseFloat(this.input2),
            parseFloat(this.input2)
          )
          .then(res => {
            console.log(res);
            if (res.state == 200) {
              this.$message({
                message: "多次分拣成功",
                type: "success"
              });
              this.dialogVisible2 = false;
              this.date = this.getDay(-1, "-");
              this.fj(0);
              this.selectOutOfStockCommodity();
              this.input1 = "";
            }
          });
      }
    },
    fj(e) {
      if (e == 0) {
        this.fjIndex = e;
        console.log(this.fjIndex);
        this.fjStyle = this.nofjStyle;
        this.fjStyle1 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
        this.fjStyle2 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
      }
      if (e == 1) {
        this.fjIndex = e;
        console.log(this.fjIndex);
        this.fjStyle1 = this.nofjStyle;
        this.fjStyle =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
        this.fjStyle2 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
      }
      if (e == 2) {
        this.fjIndex = e;
        console.log(this.fjIndex);
        this.fjStyle2 = this.nofjStyle;
        this.fjStyle =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
        this.fjStyle1 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
      }
    },
    cz(e1, e2, e3, e4, e5, e6, e7) {
      this.clientNo = e3;
      this.clientName = e4;
      this.sortingId = e5;
      this.isStockout = e6;
      this.commodityName = e7;
      if (e1 == e2) {
        this.fenjianFlag = true;
        this.inputFlag = true;
      } else {
        this.fenjianFlag = false;
        this.inputFlag = false;
      }
      this.dialogVisible2 = true;
      api.viewDetails(this.sortingId).then(res => {
        console.log(res);
        this.viewDetailsData = res.data;
      });
    },
    xzkz() {
      this.drawer1 = true;
    },
    // 查询缺货商品接口
    selectOutOfStockCommodity() {
      api
        .selectOutOfStockCommodity(
          localStorage.identity,
          this.search,
          this.date,
          this.sfbpValue,
          this.warehouseValue
        )
        .then(res => {
          console.log(res);
          this.searchData = res.data;
        });
    },
    // 查询送货时间接口
    selectDeliveryTime() {
      api.selectDeliveryTime().then(res => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.shsjData.push({
            value: item.deliveryTimeId,
            label: item.deliveryTime
          });
        });
      });
    },
    // 查询供应商接口
    queryProvider() {
      api.queryProvider().then(res => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.gysData.push(item);
        });
      });
    },
    // 查询线路接口
    selectDeliveryLine() {
      api.selectDeliveryLine().then(res => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.lxData.push(item);
        });
      });
    },
    // 查询仓库接口
    selectWarehouse() {
      api.selectWarehouse().then(res => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.warehouseData.push({
            value: item.warehouseId,
            label: item.warehouseName
          });
        });
      });
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    fhrqdj(e) {
      this.dateIndex = e;
      if (e == 0) {
        this.date = this.getDay(-1, "-");
      }
      if (e == 1) {
        this.date = this.getDay(0, "-");
      }
      if (e == 2) {
        this.date = this.getDay(1, "-");
      }
    },
    fjztdj(e) {
      this.fjztIndex = e;
      if (this.fjztIndex == 0) {
        this.fjztValue == "";
      }
      if (this.fjztIndex == 1) {
        this.fjztValue == 1;
      }
      if (this.fjztIndex == 2) {
        this.fjztValue == 0;
      }
    },
    sfbpdj(e) {
      this.sfbpIndex = e;
      if (this.sfbpIndex == 0) {
        this.sfbpValue == "";
      }
      if (this.sfbpIndex == 1) {
        this.sfbpValue == 1;
      }
      if (this.sfbpIndex == 2) {
        this.sfbpValue == 0;
      }
    },
    lxdj(e, e1) {
      // console.log(e)
      if (this.lxIndex.indexOf(e) == -1) {
        this.lxIndex.push(e);
        this.lxValue.push(e1);
        this.lineId = this.lxValue.join(",");
      } else {
        this.lxIndex.splice(this.lxIndex.indexOf(e), 1);
        this.lxValue.splice(this.lxValue.indexOf(e1), 1);
        this.lineId = this.lxValue.join(",");
      }
      console.log(this.lxIndex);
    },
    gysdj(e, e1) {
      this.gysIndex = e;
      this.gysValue = e1;
    },
    sx() {
      this.drawer = true;
    },
    kzdj(e) {
      console.log(e);
      this.kzIndex = e;
    },
    jian(e1, e2) {
      if (this.kzData[e1].value > 0) {
        this.kzData[e1].value--;
      } else {
        this.kzData[e1].value = 0;
      }
    },
    jia(e1, e2) {
      this.kzData[e1].value++;
    }
  },
  activated() {
    this.shipmentsDate = localStorage.shipmentsDate;
    // this.date = this.getDay(0, "-");
    this.date = localStorage.shipmentsDate;
    this.selectWarehouse();
    this.selectDeliveryLine();
    this.queryProvider();
    this.selectDeliveryTime();
    this.selectOutOfStockCommodity();
    this.fj(0);
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.a {
  width: 100%;
  height: calc(5vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: white;
}
.c {
  width: 15%;
  height: calc(95vh);
  background-color: rgb(39, 37, 37);
  overflow: auto;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: white;
}
::v-deep .el-drawer__header {
  display: none;
}
</style>