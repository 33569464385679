import axios from "../../kit/axios_config";
import qs from "qs";

export default {
  // 查询订单差异接口
  orderDifference(
    identity,
    shipmentsDate,
    warehouseId,
    type,
    commodityId,
    commodityKeyword,
    clientId,
    clientKeyword,
    isRough,
    page,
    pageCount
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortDifference/orderDifference", {
          params: {
            identity,
            shipmentsDate,
            warehouseId,
            type,
            commodityId,
            commodityKeyword,
            clientId,
            clientKeyword,
            isRough,
            page,
            pageCount,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询按客户分拣客户列表接口
  select(
    identity,
    clientName,
    shipmentsDate,
    sortingState,
    isRough,
    deliveryTimeId,
    warehouseId,
    lineId
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCustomer/select", {
          params: {
            identity,
            clientName,
            shipmentsDate,
            sortingState,
            isRough,
            deliveryTimeId,
            warehouseId,
            lineId,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询指定客户下的分拣商品列表接口
  selectSortingList(clientId, shipmentsDate, commodityName, sort) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCustomer/selectSortingList", {
          params: { clientId, shipmentsDate, commodityName, sort },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击操作分拣商品接口查询接口
  viewDetails(sortingId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/viewDetails", { params: { sortingId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 重置商品分拣接口
  resetSorting(sortingId, orderId, commodityId, sortingUnits) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/resetSorting", {
          params: { sortingId, orderId, commodityId, sortingUnits },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 标记缺货接口
  signOutStock(sortingId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/signOutStock", { params: { sortingId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 多次分拣接口
  manyTimesSorting(
    sortingId,
    orderId,
    commodityId,
    sortingUnits,
    orderNum,
    practicalNum,
    thisNum
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/manyTimesSorting", {
          params: {
            sortingId,
            orderId,
            commodityId,
            sortingUnits,
            orderNum,
            practicalNum,
            thisNum,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 分拣商品接口
  sortingCommodity(
    sortingId,
    orderId,
    commodityId,
    sortingUnits,
    orderNum,
    practicalNum
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/sortingCommodity", {
          params: {
            sortingId,
            orderId,
            commodityId,
            sortingUnits,
            orderNum,
            practicalNum,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询送货时间接口
  selectDeliveryTime() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryTime").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询仓库接口
  selectWarehouse() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectWarehouse").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询线路接口
  selectDeliveryLine() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryLine").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询供应商接口
  queryProvider() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/queryProvider").then((res) => {
        resolve(res.data);
      });
    });
  },
};
