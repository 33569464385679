<template>
  <div>
    <div class="a">
      <div style="width:7%;display:flex">
        <div
          style="display:flex;alignItems:center;margin:auto"
          @click="$router.push('index')"
        >
          <img src="../assets/back.png" alt style="width:15px;height:15px" />
          <div style="color:white;fontSize:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:88%">
        <el-input
          placeholder="请输入商品名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="width:900;margin:auto"
          @input="select1(search)"
        ></el-input>
      </div>
      <div
        style="display:flex;width:20%;margin-left:10px;flex-flow: row-reverse;"
      >
        <!-- <div class="b" style="backgroundColor:blue;">
          <div class="sx" @click="xzkz">选择筐重</div>
        </div> -->
        <div class="b" style="backgroundColor:rgb(66,151,178);">
          <div class="sx" @click="sx">筛选</div>
        </div>
      </div>
    </div>
    <div style="display:flex;flex-diretion:row;margin-top:20px">
      <div :style="sganIndex == 1 ? sganStyle : sganStyle1" @click="sganbh(1)">
        <div style="margin:auto">下单数量变化</div>
      </div>
      <div :style="sganIndex == 2 ? sganStyle : sganStyle1" @click="sganbh(2)">
        <div style="margin:auto">下单商品删除</div>
      </div>
      <div :style="sganIndex == 3 ? sganStyle : sganStyle1" @click="sganbh(3)">
        <div style="margin:auto">核算数量变化</div>
      </div>
      <div :style="sganIndex == 4 ? sganStyle : sganStyle1" @click="sganbh(4)">
        <div style="margin:auto">核算商品删除</div>
      </div>
    </div>
    <!-- 表格 -->
    <div style="margin:20px">
      <el-table :data="tableData" height="450px">
        <el-table-column
          :show-overflow-tooltip="true"
          prop="commodityName"
          label="商品名称"
          fixed="left"
          width="150px"
        ></el-table-column>
        <!-- <el-table-column
          :show-overflow-tooltip="true"
          prop="name"
          label="临时分拣码"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="address"
          label="库区/库位"
        ></el-table-column> -->
        <el-table-column
          :show-overflow-tooltip="true"
          prop="clientName"
          label="客户名称"
          width="150px"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="orderNum"
          label="订购数量"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="practicalNum"
          label="实际数量"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="commodityMetering"
          label="单位"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="date"
          label="描述"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="remark"
          label="备注"
          width="150px"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="date"
          label="异常情况"
          width="150px"
        >
          <template slot-scope="scope">
            <div>{{ exceptionList[scope.row.type].label }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="existingInventory"
          label="库存"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="date"
          label="分拣状态"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.sortingState == 1 ? "已分拣" : "未分拣" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="sorterName"
          label="分拣员"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 表格 -->
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageCount"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin:20px"
    ></el-pagination>
    <!-- 分页 -->
    <div>
      <el-drawer
        title="筛选"
        :visible.sync="drawer"
        :direction="direction"
        size="55%"
      >
        <div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">发货日期</div>
            <div style="display:flex;flex-direction:row">
              <div
                style="width:120px;height:40px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in dateData"
                :key="index"
                @click="fhrqdj(index)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="dateIndex == index"
                >
                  {{ item }}
                </div>
                <div style="margin:auto" v-else>{{ item }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="dateIndex == index"
                />
              </div>
              <div style="width:120px;height:60px;margin:10px">
                <el-date-picker
                  v-model="shipmentsDate"
                  type="date"
                  placeholder="选择日期"
                  style
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div style="display:flex;flex-direction:row">
            <div style="display:flex">
              <div style="margin:10px 0 0 10px;fontWeight:bold">分拣状态</div>
              <div style="display:flex;flex-direction:row">
                <el-select v-model="fjztValue" placeholder="请选择">
                  <el-option
                    v-for="item in fjztData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item, index) in fjztData"
                  :key="index"
                  @click="fjztdj(index)"
                >
                  <div
                    style="margin:auto;color:rgb(66,151,178)"
                    v-if="fjztIndex == index"
                  >
                    {{ item }}
                  </div>
                  <div style="margin:auto" v-else>{{ item }}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="fjztIndex == index"
                  />
                </div> -->
              </div>
            </div>
            <div style="display:flex">
              <div style="margin:10px 0 0 10px;fontWeight:bold">是否标品</div>
              <div style="display:flex;flex-direction:row">
                <el-select v-model="sfbpValue" placeholder="请选择">
                  <el-option
                    v-for="item in sfbpData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item, index) in sfbpData"
                  :key="index"
                  @click="sfbpdj(index)"
                >
                  <div
                    style="margin:auto;color:rgb(66,151,178)"
                    v-if="sfbpIndex == index"
                  >
                    {{ item }}
                  </div>
                  <div style="margin:auto" v-else>{{ item }}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="sfbpIndex == index"
                  />
                </div> -->
              </div>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">更多筛选条件</div>
            <div style="margin:10px">
              <el-select
                v-model="warehouseValue"
                placeholder="请选择仓库"
                style="margin-left:10px"
              >
                <el-option
                  v-for="item in warehouseData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-model="shsjValue"
                placeholder="请选择收货时间"
                style="margin-left:10px"
              >
                <el-option
                  v-for="item in shsjData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">请选择路线</div>
            <div style="marginLeft:20px;marginTop:10px">
              <el-select v-model="path" placeholder="请选择">
                <el-option
                  v-for="item in lxData"
                  :key="item.lineId"
                  :label="item.lineName"
                  :value="item.lineId"
                >
                </el-option>
              </el-select>
              <!-- <div
                style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in lxData"
                :key="index"
                @click="lxdj(index, item.lineId)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="lxIndex.indexOf(index) != -1"
                >
                  {{ item.lineName }}
                </div>
                <div style="margin:auto" v-else>{{ item.lineName }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="lxIndex.indexOf(index) != -1"
                />
              </div> -->
            </div>
          </div>
          <div style="display:flex;flex-direcion:row;justify-content:flex-end;">
            <div
              style="width:250px;height:60px;display:flex;border:1px solid black;margin-right:20px"
              @click="() => (drawer = false)"
            >
              <div style="margin:auto">取消</div>
            </div>
            <div
              @click="
                sure();
                radioIndex = null;
              "
              style="width:250px;height:60px;display:flex;background-color:rgb(66,151,178);margin-right:20px"
            >
              <div style="margin:auto;color:white">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-drawer :visible.sync="drawer1" :direction="direction" size="40%">
        <div>
          <div>
            <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <div
                style="width:200px;height:80px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in kzData"
                :key="index"
              >
                <div
                  v-if="item.item1 != '不设置称重筐' && kzIndex == index"
                  @click="kzdj(index)"
                >
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:50%"
                  >
                    <span style="margin:auto">{{
                      item.item1 + " " + item.item2
                    }}</span>
                  </div>
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:50%"
                  >
                    <div style="margin:auto">
                      <span @click="jian(index, item.value)">-</span>
                      <span style="margin:0 20px 0 20px">{{ item.value }}</span>
                      <span @click="jia(index, item.value)">+</span>
                    </div>
                  </div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="kzIndex == index"
                  />
                </div>
                <div v-if="item.item1 == '不设置称重筐'" @click="kzdj(index)">
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:100%"
                  >
                    <span style="margin:auto">{{ item.item1 }}</span>
                  </div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="kzIndex == index"
                  />
                </div>
                <div
                  v-if="item.item1 != '不设置称重筐' && kzIndex != index"
                  @click="kzdj(index)"
                >
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:100%"
                  >
                    <span style="margin:auto">{{
                      item.item1 + " " + item.item2
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="display:flex;flex-direction:row;justify-content:space-around;height:50%;margin-top:10px"
          >
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;border:1px solid black"
              @click="drawer1 = false"
            >
              <div style="margin:auto;fontSize:20px">取消</div>
            </div>
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;backgroundColor:rgb(66,151,178);color:white"
              @click="drawer1 = false"
            >
              <div style="margin:auto;fontSize:20px">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import api from "../apis/ddfjcy/ddfjcy";
export default {
  data() {
    return {
      exceptionList: [
        {
          value: "",
          label: "全部异常情况",
        },
        {
          value: 1,
          label: "下单数量变化",
        },

        {
          value: 2,
          label: "下单商品删除",
        },
        {
          value: 3,
          label: "核算数量变化",
        },
        {
          value: 4,
          label: "核算商品删除",
        },
      ], //异常情况列表
      path: "",
      value: "",
      options: [],
      drawer: false,
      drawer1: false,
      direction: "ttb",
      search: "",
      radio: "1",
      radioData: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "肉类新月",
        },
      ],
      shouqi: "— 收起",
      shouqiflag: true,
      dialogVisible: false,
      dateData: ["昨日", "今日", "明日"],
      dateIndex: 0,
      date: "",
      fjztData: [
        { label: "全部", value: "" },
        { label: "已分拣", value: 1 },
        { label: "未分拣", value: 0 },
      ],
      fjztIndex: 0,
      fjztValue: "",
      sfbpData: [
        { label: "全部", value: "" },
        { label: "标品", value: 1 },
        { label: "未标品", value: 0 },
      ],
      sfbpIndex: 0,
      sfbpValue: "",
      lxData: [],
      lxIndex: [],
      path: [],
      lineId: "",
      gysData: [{ providerName: "全部供应商", providerId: "" }],
      gysIndex: 0,
      dialogVisible1: false,
      searchData: [],
      czStyle1:
        "width:300px;height:200px;background-color:rgb(66,180,133);border:1px solid grey;",
      czStyle2:
        "width:300px;height:200px;background-color:rgb(197,74,74);border:1px solid grey;",
      warehouseData: [],
      warehouseValue: "",
      shsjData: [],
      shsjValue: "",
      searchData1: [],
      shipmentsDate: "",
      kzData: [
        {
          item1: "不设置称重筐",
          item2: "",
          value: "",
        },
        {
          item1: "测试数据1",
          item2: "11",
          value: 1,
        },
        {
          item1: "测试数据2",
          item2: "12",
          value: 1,
        },
        {
          item1: "测试数据3",
          item2: "13",
          value: 1,
        },

        {
          item1: "测试数据4",
          item2: "14",
          value: 1,
        },
        {
          item1: "测试数据5",
          item2: "15",
          value: 1,
        },
        {
          item1: "测试数据6",
          item2: "16",
          value: 1,
        },
        {
          item1: "测试数据7",
          item2: "17",
          value: 1,
        },
        {
          item1: "测试数据8",
          item2: "18",
          value: 1,
        },
        {
          item1: "测试数据9",
          item2: "19",
          value: 1,
        },
        {
          item1: "测试数据10",
          item2: "20",
          value: 1,
        },
      ],
      kzIndex: "",
      sganIndex: 1,
      sganStyle:
        "width:150px;height:50px;backgroundColor:rgb(66,180,133);display:flex;;margin-left:20px;fontSize:20px;color:white;border:1px solid grey",
      sganStyle1:
        "width:150px;height:50px;backgroundColor:white;display:flex;;margin-left:20px;fontSize:20px;color:black;border:1px solid grey",
      page: 1, //页码
      pageCount: 10, //每页显示数量
      total: 0, //数据总条数
      tableData: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    // 离开当前路由页面时调用
    this.drawer = false;
    next();
  },
  methods: {
    sure() {
      this.radioData = [{ label: "全部", value: "" }];
      this.firstTypeId = null;
      this.secondTypeId = null;
      this.orderDifference();
      this.drawer = false;
    },
    // 分页
    // 每页显示的条数
    handleSizeChange(val) {
      // 改变每页显示的条数
      this.pageCount = val;
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.page = 1;
      this.orderDifference();
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.page = val;
      this.orderDifference();
    },
    orderDifference() {
      api
        .orderDifference(
          localStorage.identity,
          this.shipmentsDate,
          this.warehouseValue,
          this.sganIndex,
          "",
          this.search,
          "",
          "",
          this.sfbpValue,
          this.page,
          this.pageCount
        )
        .then((res) => {
          console.log(res);
          this.tableData = res.data;
          this.total = res.pageData;
        });
    },
    sganbh(e) {
      if (e == 1) {
        this.sganIndex = e;
        this.orderDifference();
      }
      if (e == 2) {
        this.sganIndex = e;
        this.orderDifference();
      }
      if (e == 3) {
        this.sganIndex = e;
        this.orderDifference();
      }
      if (e == 4) {
        this.sganIndex = e;
        this.orderDifference();
      }
    },
    kzdj(e) {
      console.log(e);
      this.kzIndex = e;
    },
    jian(e1, e2) {
      if (this.kzData[e1].value > 0) {
        this.kzData[e1].value--;
      } else {
        this.kzData[e1].value = 0;
      }
    },
    jia(e1, e2) {
      this.kzData[e1].value++;
    },
    xzkz() {
      this.drawer1 = true;
    },
    select1(search) {
      this.select();
    },
    select() {
      api
        .select(
          localStorage.identity,
          this.search,
          localStorage.shipmentsDate,
          this.fjztValue,
          this.sfbpValue,
          this.shsjValue,
          this.warehouseValue,
          this.path
        )
        .then((res) => {
          console.log(res);
          this.searchData1 = res.data;
        });
    },
    // 查询送货时间接口
    selectDeliveryTime() {
      api.selectDeliveryTime().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.shsjData.push({
            value: item.deliveryTimeId,
            label: item.deliveryTime,
          });
        });
      });
    },
    // 查询供应商接口
    queryProvider() {
      api.queryProvider().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.gysData.push(item);
        });
      });
    },
    // 查询线路接口
    selectDeliveryLine() {
      api.selectDeliveryLine().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.lxData.push(item);
        });
      });
    },
    // 查询仓库接口
    selectWarehouse() {
      api.selectWarehouse().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.warehouseData.push({
            value: item.warehouseId,
            label: item.warehouseName,
          });
        });
      });
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    fhrqdj(e) {
      this.dateIndex = e;
      if (e == 0) {
        this.shipmentsDate = this.getDay(0, "-");
      }
      if (e == 1) {
        this.shipmentsDate = this.getDay(1, "-");
      }
      if (e == 2) {
        this.shipmentsDate = this.getDay(2, "-");
      }
    },
    fjztdj(e) {
      this.fjztIndex = e;
      if (this.fjztIndex == 0) {
        this.fjztValue == "";
      }
      if (this.fjztIndex == 1) {
        this.fjztValue == 1;
      }
      if (this.fjztIndex == 2) {
        this.fjztValue == 0;
      }
    },
    sfbpdj(e) {
      this.sfbpIndex = e;
      if (this.sfbpIndex == 0) {
        this.sfbpValue == "";
      }
      if (this.sfbpIndex == 1) {
        this.sfbpValue == 1;
      }
      if (this.sfbpIndex == 2) {
        this.sfbpValue == 0;
      }
    },
    lxdj(e, e1) {
      // console.log(e)
      if (this.lxIndex.indexOf(e) == -1) {
        this.lxIndex.push(e);
        this.path.push(e1);
        this.lineId = this.path.join(",");
      } else {
        this.lxIndex.splice(this.lxIndex.indexOf(e), 1);
        this.path.splice(this.path.indexOf(e1), 1);
        this.lineId = this.path.join(",");
      }
      console.log(this.lxIndex);
    },
    gysdj(e) {
      this.gysIndex = e;
    },
    sx() {
      this.drawer = true;
    },
  },
  mounted() {
    this.shipmentsDate = localStorage.shipmentsDate;
    // this.shipmentsDate = this.getDay(0, "-");
    this.shipmentsDate = localStorage.shipmentsDate;
    this.selectWarehouse();
    this.selectDeliveryLine();
    this.queryProvider();
    this.selectDeliveryTime();
    // this.select();
    this.orderDifference();
  },
};
</script>

<style scoped>
.a {
  width: 100%;
  height: calc(10vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: white;
}
.c {
  width: 15%;
  height: calc(95vh);
  background-color: rgb(39, 37, 37);
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: white;
}
::v-deep .el-drawer__header {
  display: none;
}
</style>
