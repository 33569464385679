<template>
  <div style="background-color:#ddd;height:100vh">
    <div class="a">
      <div style="width:7%;display:flex">
        <div
          style="display:flex;alignItems:center;margin:auto"
          @click="$router.push('akhfj')"
        >
          <img src="../assets/back.png" alt style="width:15px;height:15px" />
          <div style="color:white;fontSize:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:73%">
        <el-input
          placeholder="请输入商品名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="width:900;margin:auto"
          @input="select1(search)"
        ></el-input>
      </div>
      <div
        style="display:flex;width:30%;margin-left:10px;flex-flow: row-reverse;"
      >
        <div class="b" style="backgroundColor:rgb(66,180,133);">
          <div class="yjfj" @click="pxfs" style="fontSize:15px">排序方式</div>
        </div>
        <div class="b" style="backgroundColor:rgb(197,74,74);">
          <div class="yjfj" @click="yjfj()">一键分拣</div>
        </div>
        <!-- <div class="b" style="backgroundColor:rgb(66,180,133);">
          <div class="yjfj" @click="btsz" style="fontSize:15px">表头设置</div>
        </div> -->
        <!-- <div class="b" style="backgroundColor:rgb(66,151,178);">
          <div class="sx" @click="sx" style="fontSize:15px">选择筐重</div>
        </div> -->
        <div class="b" style="backgroundColor:rgb(66,180,133);">
          <div class="yjdy" @click="yjdy()">一键打印</div>
        </div>
      </div>
    </div>
    <div>
      <div
        style="display:flex;flex-direction:row;alignItems:center;margin:20px"
      >
        <div style="fontWeight:bold;fontSize:20px">{{ clientName1 }}</div>
      </div>
    </div>
    <div>
      <div
        style="display:flex;flex-direciton:row; flex-flow: row wrap;position:relative;"
      >
        <div
          v-for="(item, index) in searchData"
          :key="index"
          style="margin:5.5px;"
        >
          <div :style="item.state == 0 ? czStyle2 : czStyle1">
            <div
              style="position:absolute;width:35px;height:35px;border:1px solid #FF8C00;border-radius:25px;display:flex;left:20px;top:75px"
              v-if="item.isStockout == 1"
            >
              <div style="color:#FF8C00;fontSize:10px;margin:auto">缺货</div>
            </div>
            <div
              style="display:flex;flex-direciton:row;justify-content:space-between"
            >
              <div style="width:85%;margin:auto;color:white">
                <div style="height:50px;margin:auto;display:flex;fontSize:20px">
                  <div style="margin:auto">
                    <span>{{ item.commodityName }}</span>
                  </div>
                </div>
                <div style="height:75px;margin:auto;display:flex;">
                  <div style="margin:auto;fontSize:25px">
                    <div>{{ item.orderNum }}{{ item.commodityMetering }}</div>
                  </div>
                </div>
                <div style="height:75px;margin:auto;display:flex;">
                  <div style="margin:auto">
                    <div>
                      称重：{{ item.practicalNum }}{{ item.commodityMetering }}
                    </div>
                    <div>
                      {{ item.tips }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="width:15%;margin:auto;display:flex;;background-color:white;height:200px;text-align: center;background-color:#ddd"
              >
                <div style="margin:auto;fontSize:25px">
                  <div @click="cz(item)">
                    操作
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible1" width="30%">
        <span
          >将有{{
            num
          }}个标签被打印（注意：此功能会分拣并打印所有未分拣的商品），确定全部打印？</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="yjfj(1)">是</el-button>
          <el-button @click="dialogVisible1 = false">否</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-drawer :visible.sync="drawer" :direction="direction" size="40%">
        <div>
          <div>
            <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <div
                style="width:200px;height:80px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in kzData"
                :key="index"
              >
                <div
                  v-if="item.item1 != '不设置称重筐' && kzIndex == index"
                  @click="kzdj(index)"
                >
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:50%"
                  >
                    <span style="margin:auto">{{
                      item.item1 + " " + item.item2
                    }}</span>
                  </div>
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:50%"
                  >
                    <div style="margin:auto">
                      <span @click="jian(index, item.value)">-</span>
                      <span style="margin:0 20px 0 20px">{{ item.value }}</span>
                      <span @click="jia(index, item.value)">+</span>
                    </div>
                  </div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="kzIndex == index"
                  />
                </div>
                <div v-if="item.item1 == '不设置称重筐'" @click="kzdj(index)">
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:100%"
                  >
                    <span style="margin:auto">{{ item.item1 }}</span>
                  </div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="kzIndex == index"
                  />
                </div>
                <div
                  v-if="item.item1 != '不设置称重筐' && kzIndex != index"
                  @click="kzdj(index)"
                >
                  <div
                    style="display:flex;flex-direction:row;width:200px;height:100%"
                  >
                    <span style="margin:auto">{{
                      item.item1 + " " + item.item2
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="display:flex;flex-direction:row;justify-content:space-around;height:50%;margin-top:10px"
          >
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;border:1px solid black"
              @click="drawer = false"
            >
              <div style="margin:auto;fontSize:20px">取消</div>
            </div>
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;backgroundColor:rgb(66,151,178);color:white"
              @click="drawer = false"
            >
              <div style="margin:auto;fontSize:20px">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div></div>
    <div>
      <el-drawer :visible.sync="drawer1" :direction="direction" size="25%">
        <div>
          <div>
            <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <div
                style="width:200px;height:80px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in btszData"
                :key="index"
              >
                <div
                  style="display:flex;flex-direction:row;width:200px;height:100%"
                  @click="btszdj(index)"
                >
                  <span style="margin:auto">{{ item }}</span>
                </div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="btszIndex.indexOf(index) != -1"
                />
              </div>
            </div>
          </div>
          <div
            style="display:flex;flex-direction:row;justify-content:space-around;height:50%;margin-top:10px"
          >
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;border:1px solid black"
              @click="drawer1 = false"
            >
              <div style="margin:auto;fontSize:20px">取消</div>
            </div>
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;backgroundColor:rgb(66,151,178);color:white"
              @click="drawer1 = false"
            >
              <div style="margin:auto;fontSize:20px">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-drawer :visible.sync="drawer2" :direction="direction" size="25%">
        <div>
          <div>
            <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
              <div
                style="width:200px;height:80px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in pxfsData"
                :key="index"
              >
                <div
                  style="display:flex;flex-direction:row;width:200px;height:100%"
                  @click="pxfsdj(index)"
                >
                  <span style="margin:auto">{{ item }}</span>
                </div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="pxfsIndex == index"
                />
              </div>
            </div>
          </div>
          <div
            style="display:flex;flex-direction:row;justify-content:space-around;height:50%;margin-top:10px"
          >
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;border:1px solid black"
              @click="drawer2 = false"
            >
              <div style="margin:auto;fontSize:20px">取消</div>
            </div>
            <div
              style="display:flex;flex-direction:row;width:49%;height:70px;backgroundColor:rgb(66,151,178);color:white"
              @click="drawer2 = false"
            >
              <div style="margin:auto;fontSize:20px">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-dialog
        :title="commodityName1 + ' [ ' + clientNo + ' - ' + clientName + ' ] '"
        :visible.sync="dialogVisible2"
        width="45%"
      >
        <div style="width:100%;display:flex;flex-direction:row;">
          <div style="width:50%;display:flex;flex-direction:row;height:60px">
            <div :style="fjStyle" @click="fj(0)">
              <div style="margin:auto;fontSize:20px">分拣</div>
            </div>
            <div :style="fjStyle1" @click="fj(1)" v-if="!fenjianFlag">
              <div style="margin:auto;fontSize:20px">多次分拣</div>
            </div>
            <!-- <div :style="fjStyle2" @click="fj(2)" v-if="!fenjianFlag">
              <div style="margin:auto;fontSize:20px">定值分拣</div>
            </div>-->
          </div>
          <div style="display:flex;flex-direction:row;">
            <div
              style="width:100px;display:flex;backgroundColor:rgb(66,180,133);color:white;margin-left:10px"
              @click="reset()"
              v-if="viewDetailsData.sortingRecord != 0 || isStockout == 1"
            >
              <div style="margin:auto;fontSize:30px">重置</div>
            </div>
            <div
              @click="print1"
              style="width:100px;display:flex;backgroundColor:rgb(66,151,178);color:white;margin-left:10px"
            >
              <div style="margin:auto;fontSize:30px">打印</div>
            </div>
            <div
              style="width:150px;display:flex;backgroundColor:orange;color:white;margin-left:10px"
              v-if="!fenjianFlag && isStockout != 1"
              @click="bjqh()"
            >
              <div style="margin:auto;fontSize:30px">标记缺货</div>
            </div>
          </div>
        </div>
        <div style="margin:20px 0 20px 0">
          <span style="fontSize:20px">订购数量：</span>
          <span style="fontSize:20px;color:rgb(197,74,74)"
            >{{ viewDetailsData.orderNum
            }}{{ viewDetailsData.commodityMetering }}</span
          >
        </div>
        <el-input
          v-model="input"
          :disabled="inputFlag"
          style="fontSize:35px"
          v-if="fjIndex != 1"
        ></el-input>
        <el-input v-model="input1" style="fontSize:35px" v-if="fjIndex == 1"></el-input>
        <div v-if="fjIndex == 0 && !fenjianFlag">
          <div style="margin-top:20px">
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(1)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  1
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(2)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  2
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(3)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  3
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('删除')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  删除
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(4)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  4
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(5)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  5
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(6)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  6
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('清空')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  清空
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(7)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  7
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(8)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  8
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(9)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  9
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('.')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  .
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu('返回')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  返回
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu(0)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  0
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:50%;border:1px solid black"
                @click="anniu('完成')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  完成
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="fjIndex == 1">
          <div
            style="display:flex;flex-direction:row;justify-content:space-between;margin:20px 0 20px 0;fontSize:20px"
          >
            <div>
              <span>分拣记录：</span>
              <span>{{ viewDetailsData.sortingRecord }}</span>
            </div>
            <div style="display:flex;flex-direction:row">
              <div>
                <span>分拣总和：</span>
                <span
                  >{{ viewDetailsData.sortingRecord
                  }}{{ viewDetailsData.commodityMetering }}</span
                >
              </div>
              <div style="margin-left:20px">
                <span>未分拣量：</span>
                <span
                  >{{ viewDetailsData.unsortedQuantity
                  }}{{ viewDetailsData.commodityMetering }}</span
                >
              </div>
            </div>
          </div>
          <div style="margin-top:20px">
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(1)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  1
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(2)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  2
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(3)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  3
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('删除')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  删除
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(4)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  4
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(5)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  5
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(6)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  6
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('清空')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  清空
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(7)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  7
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(8)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  8
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1(9)"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  9
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('.')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  .
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('返回')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  返回
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('0')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  0
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('记录称重')"
              >
                <div
                  style="margin:auto;fontSize:20px;color:black;fontWeight:bold"
                >
                  记录称重
                </div>
              </div>
              <div
                style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black"
                @click="anniu1('完成')"
              >
                <div
                  style="margin:auto;fontSize:30px;color:black;fontWeight:bold"
                >
                  完成
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="fjIndex == 2">
          <div style="display:flex;flex-direction:row;alignItems:center">
            <el-input v-model="input" style="width:80%"></el-input>
            <span style="fontSize:20px;;margin-left:20px">袋</span>
          </div>
          <div style="display:flex;flex-direction:row;alignItems:center;margin-top:20px">
            <el-input v-model="input" style="width:80%"></el-input>
            <span style="fontSize:20px;;margin-left:20px">份</span>
          </div>
          <div style="margin-top:20px">
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">1</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">2</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">3</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">删除</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">4</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">5</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">6</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">清空</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">7</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">8</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">9</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">.</div>
              </div>
            </div>
            <div style="display:flex;flex-direction:row;height:60px">
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">返回</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">0</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">记录称重</div>
              </div>
              <div style="display:flex;backgroundColor:#E6E6FA;width:25%;border:1px solid black">
                <div style="margin:auto;fontSize:30px;color:black;fontWeight:bold">完成</div>
              </div>
            </div>
          </div>
        </div>-->
        <div v-if="fenjianFlag">
          <div style="width:100%;height:300px;display:flex">
            <div style="margin:auto;fontSize:50px">已分拣</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span style="fontSize:20px">是否确定一键打印</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="print()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../apis/aspfj/aspfj";
import { PrintAccount } from "../print/doPrint";
export default {
  data() {
    return {
      num: 0,
      inputFlag: false,
      input: "",
      input1: "",
      value: "",
      options: [],
      drawer: false,
      direction: "ttb",
      search: "",
      shouqi: "— 收起",
      shouqiflag: true,
      dialogVisible: false,
      kzData: [
        {
          item1: "不设置称重筐",
          item2: "",
          value: "",
        },
        {
          item1: "测试数据1",
          item2: "11",
          value: 1,
        },
        {
          item1: "测试数据2",
          item2: "12",
          value: 1,
        },
        {
          item1: "测试数据3",
          item2: "13",
          value: 1,
        },

        {
          item1: "测试数据4",
          item2: "14",
          value: 1,
        },
        {
          item1: "测试数据5",
          item2: "15",
          value: 1,
        },
        {
          item1: "测试数据6",
          item2: "16",
          value: 1,
        },
        {
          item1: "测试数据7",
          item2: "17",
          value: 1,
        },
        {
          item1: "测试数据8",
          item2: "18",
          value: 1,
        },
        {
          item1: "测试数据9",
          item2: "19",
          value: 1,
        },
        {
          item1: "测试数据10",
          item2: "20",
          value: 1,
        },
      ],
      kzIndex: 0,
      dialogVisible1: false,
      danweiData: [],
      danweiIndex: 0,
      drawer1: false,
      btszData: [
        "商品备注",
        "订单备注",
        "供应商/采购员",
        "内部备注",
        "库区库位",
      ],
      btszIndex: [],
      drawer2: false,
      pxfsData: [
        "默认排序",
        "按客户名称",
        "按客户编码",
        "按下单数量",
        "按线路",
      ],
      pxfsIndex: 0,
      dialogVisible2: false,
      fjIndex: 0,
      fjStyle: "width:33%;display:flex;backgroundColor:#E6E6FA;color:black",
      fjStyle1: "width:33%;display:flex;backgroundColor:#E6E6FA;color:black",
      fjStyle2: "width:33%;display:flex;backgroundColor:#E6E6FA;color:black",
      nofjStyle:
        "width:33%;display:flex;backgroundColor:rgb(197,74,74);color:white",
      commodityId: "",
      shipmentsDate: "",
      commodityName: "",
      commodityMetering: "",
      totalSorted: "",
      totalSorting: "",
      totalUnselected: "",
      searchData: [],
      fenjianFlag: false,
      clientNo: "",
      clientName: "",
      viewDetailsData: [],
      state: "",
      czStyle1:
        "width:300px;height:200px;background-color:rgb(66,180,133);border:1px solid grey;",
      czStyle2:
        "width:300px;height:200px;background-color:rgb(197,74,74);border:1px solid grey;",
      isStockout: "",
      clientId: "",
      clientName1: "",
      commodityName1: "",
    };
  },
  methods: {
    print1() {
      api
        .print(
          localStorage.identity,
          this.viewDetailsData.shipmentsDate,
          null,
          null,
          this.viewDetailsData.commodityMetering,
          this.viewDetailsData.commodityId,
          this.viewDetailsData.orderCommodityId
        )
        .then((res) => {
          console.log(res)
          this.$message({ type: "success", message: "打印成功" });
          res.data.forEach((val) => {
            PrintAccount(res.data2, [], val);
          });
        });
    },
    print() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.printData.forEach((val) => {
        PrintAccount(this.template, [], val);
      });
    },
    select1() {
      this.selectSortingList();
    },
    bjqh() {
      this.$confirm("是否确定标记为缺货?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .signOutStock(this.viewDetailsData.orderCommodityId)
            .then((res) => {
              if (res.state == 200) {
                this.$message({
                  type: "success",
                  message: "标记为缺货成功!",
                });
                this.dialogVisible2 = false;
                // this.shipmentsDate = this.getDay(-1, "-");
                this.fj(0);
                this.selectSortingList();
              }
            });
        })
        .catch(() => {});
    },
    reset() {
      this.$confirm("重置成功后，历史称重数据将被清除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .resetSorting(
              this.viewDetailsData.orderCommodityId,
              this.viewDetailsData.orderId,
              this.viewDetailsData.commodityId,
              this.viewDetailsData.sortingUnits
            )
            .then((res) => {
              if (res.state == 200) {
                this.$message({
                  type: "success",
                  message: "重置成功!",
                });
                this.dialogVisible2 = false;
                // this.shipmentsDate = this.getDay(-1, "-");
                this.fj(0);
                this.selectSortingList();
              }
            });
        })
        .catch(() => {});
    },
    anniu(e) {
      if (e == 1) {
        this.input = this.input + "1";
      }
      if (e == 2) {
        this.input = this.input + "2";
      }
      if (e == 3) {
        this.input = this.input + "3";
      }
      if (e == 4) {
        this.input = this.input + "4";
      }
      if (e == 5) {
        this.input = this.input + "5";
      }
      if (e == 6) {
        this.input = this.input + "6";
      }
      if (e == 7) {
        this.input = this.input + "7";
      }
      if (e == 8) {
        this.input = this.input + "8";
      }
      if (e == 9) {
        this.input = this.input + "9";
      }
      if (e == 0) {
        this.input = this.input + "0";
      }
      if (e == "删除") {
        this.input = this.input.toString().slice(0, this.input.length - 1);
      }
      if (e == "清空") {
        this.input = "";
      }
      if (e == ".") {
        if (
          this.input.indexOf(".") != -1 ||
          this.input == "" ||
          this.input.substr(this.input.indexOf(".")).length > 2
        ) {
          this.input = this.input;
        } else {
          this.input = this.input + ".";
        }
      }
      if (e == "完成") {
        console.log(this.input.substr(this.input.indexOf(".")).length);
        if (this.input.substr(this.input.indexOf(".")).length > 3) {
          this.$message({
            message: "只能输入两位小数",
            type: "error",
          });
          return;
        }
        api
          .sortingCommodity(
            this.orderCommodityId,
            this.viewDetailsData.commodityMetering,
            parseFloat(this.viewDetailsData.orderNum),
            parseFloat(this.input)
          )
          .then((res) => {
            console.log(res);
            if (res.state == 200) {
              this.$message({
                message: "分拣成功",
                type: "success",
              });
              this.dialogVisible2 = false;
              // this.shipmentsDate = this.getDay(-1, "-");
              this.fj(0);
              this.selectSortingList();

              api
                .print(
                  localStorage.identity,
                  this.viewDetailsData.shipmentsDate,
                  null,
                  null,
                  this.viewDetailsData.commodityMetering,
                  this.viewDetailsData.commodityId,
                  this.viewDetailsData.orderCommodityId,
                  this.input * 1
                )
                .then((res) => {
                  // this.dialogVisible1 = true;
                  // this.num = res.data.length;
                  // this.printData = res.data;
                  // this.template = res.data2;
                  console.log(res);
                  res.data.forEach((val) => {
                    PrintAccount(res.data2, [], val);
                  });
                });
              this.input = "";
            }
          });
      }
    },
    anniu1(e) {
      if (e == 1) {
        this.input1 = this.input1 + "1";
      }
      if (e == 2) {
        this.input1 = this.input1 + "2";
      }
      if (e == 3) {
        this.input1 = this.input1 + "3";
      }
      if (e == 4) {
        this.input1 = this.input1 + "4";
      }
      if (e == 5) {
        this.input1 = this.input1 + "5";
      }
      if (e == 6) {
        this.input1 = this.input1 + "6";
      }
      if (e == 7) {
        this.input1 = this.input1 + "7";
      }
      if (e == 8) {
        this.input1 = this.input1 + "8";
      }
      if (e == 9) {
        this.input1 = this.input1 + "9";
      }
      if (e == 0) {
        this.input1 = this.input1 + "0";
      }
      if (e == "删除") {
        this.input1 = this.input1.toString().slice(0, this.input1.length - 1);
      }
      if (e == "清空") {
        this.input1 = "";
      }
      if (e == ".") {
        if (
          this.input1.indexOf(".") != -1 ||
          this.input1 == "" ||
          this.input1.substr(this.input1.indexOf(".")).length > 2
        ) {
          this.input1 = this.input1;
        } else {
          this.input1 = this.input1 + ".";
        }
      }
      if (e == "完成" || e == "记录称重") {
        console.log(this.input1.substr(this.input1.indexOf(".")).length);
        if (this.input1.substr(this.input1.indexOf(".")).length > 3) {
          this.$message({
            message: "只能输入两位小数",
            type: "error",
          });
          return;
        }
        api
          .manyTimesSorting(
            this.orderCommodityId,
            parseFloat(this.viewDetailsData.sortingRecord),
            parseFloat(this.input1),
            parseFloat(this.viewDetailsData.orderNum),
            e == "完成" ? 1 : 0
          )
          .then((res) => {
            console.log(res);
            if (res.state == 200) {
              this.$message({
                message: "多次分拣成功",
                type: "success",
              });
              this.dialogVisible2 = false;

              // this.shipmentsDate = this.getDay(-1, "-");
              this.fj(0);

              this.select3();

              api
                .print(
                  localStorage.identity,
                  this.viewDetailsData.shipmentsDate,
                  null,
                  null,
                  this.viewDetailsData.commodityMetering,
                  this.viewDetailsData.commodityId,
                  this.viewDetailsData.orderCommodityId,
                  this.input1 * 1
                )
                .then((res) => {
                  // this.dialogVisible1 = true;
                  // this.num = res.data.length;
                  // this.printData = res.data;
                  // this.template = res.data2;
                  console.log(res);
                  res.data.forEach((val) => {
                    PrintAccount(res.data2, [], val);
                  });
                });
              this.input1 = "";
            }
          });
      }
    },
    selectSortingList() {
      api
        .selectSortingList(
          localStorage.identity,
          this.clientId,
          this.shipmentsDate,
          this.search,
          this.pxfsIndex
        )
        .then((res) => {
          console.log(res);
          this.searchData = res.data;
        });
    },
    fj(e) {
      if (e == 0) {
        this.fjIndex = e;
        console.log(this.fjIndex);
        this.fjStyle = this.nofjStyle;
        this.fjStyle1 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
        this.fjStyle2 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
      }
      if (e == 1) {
        this.fjIndex = e;
        console.log(this.fjIndex);
        this.fjStyle1 = this.nofjStyle;
        this.fjStyle =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
        this.fjStyle2 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
      }
      if (e == 2) {
        this.fjIndex = e;
        console.log(this.fjIndex);
        this.fjStyle2 = this.nofjStyle;
        this.fjStyle =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
        this.fjStyle1 =
          "width:33%;display:flex;backgroundColor:#E6E6FA;color:black";
      }
    },
    cz(item) {
      this.clientNo = item.clientNo;
      this.clientName = item.clientName;
      this.orderCommodityId = item.orderCommodityId;
      this.isStockout = item.isStockout;
      this.commodityName1 = item.commodityName;
      this.fenjianFlag = item.state == 1;
      this.inputFlag = item.state == 1;
      this.dialogVisible2 = true;
      api.viewDetails(this.orderCommodityId).then((res) => {
        console.log(res);
        this.viewDetailsData = res.data;
      });
    },
    pxfs() {
      this.drawer2 = true;
    },
    pxfsdj(e) {
      this.pxfsIndex = e;
      console.log(this.pxfsIndex);
    },
    btszdj(e) {
      // console.log(e)
      if (this.btszIndex.indexOf(e) == -1) {
        this.btszIndex.push(e);
      } else {
        this.btszIndex.splice(this.btszIndex.indexOf(e), 1);
      }
      console.log(this.btszIndex);
    },
    kzdj(e) {
      console.log(e);
      this.kzIndex = e;
    },
    jian(e1, e2) {
      if (this.kzData[e1].value > 0) {
        this.kzData[e1].value--;
      } else {
        this.kzData[e1].value = 0;
      }
    },
    jia(e1, e2) {
      this.kzData[e1].value++;
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    fhrqdj(e) {
      this.dateIndex = e;
      if (e == 0) {
        this.shipmentsDate = this.getDay(0, "-");
      }
      if (e == 1) {
        this.shipmentsDate = this.getDay(1, "-");
      }
      if (e == 2) {
        this.shipmentsDate = this.getDay(2, "-");
      }
    },
    fjztdj(e) {
      this.fjztIndex = e;
    },
    sfbpdj(e) {
      this.sfbpIndex = e;
    },
    lxdj(e) {
      // console.log(e)
      if (this.lxIndex.indexOf(e) == -1) {
        this.lxIndex.push(e);
      } else {
        this.lxIndex.splice(this.lxIndex.indexOf(e), 1);
      }
      console.log(this.lxIndex);
    },
    gysdj(e) {
      this.gysIndex = e;
    },
    shouqiweishouqi() {
      if (this.shouqi == "+ 展开") {
        this.shouqi = "— 收起";
        this.shouqiflag = true;
      } else {
        this.shouqi = "+ 展开";
        this.shouqiflag = false;
      }
    },
    yjdy(item) {
      api
        .print(
          localStorage.identity,
          this.shipmentsDate,
          null,
          null,
          this.commodityMetering,
          this.commodityId
        )
        .then((res) => {
          if (!item) this.dialogVisible = true;
          this.num = res.data.length;
          this.printData = res.data;
          this.template = res.data2;
          // console.log(res);
          // if (!item) {
          //   this.$message({ type: "success", message: "打印成功" });
          //   res.data.forEach((val) => {
          //     PrintAccount(res.data2, [], val);
          //   });
          // }
        });
    },
    sx() {
      this.drawer = true;
    },
    yjfj(isAllow) {
      // this.dialogVisible1 = true;
      // this.yjdy(1);
      // if (!isAllow) return;
      // api
      //   .sortingAllGoods({
      //     sorterId: localStorage.identity,
      //     shipmentsDate: this.shipmentsDate,
      //     firstTypeId: null,
      //     secondTypeId: null,
      //     keyword: null,
      //     commodityId: this.commodityId,
      //     commodityMetering: this.commodityMetering,
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     if (res.state == 200) {
      //       this.$message({ type: "success", message: "分拣成功" });
      //       this.print();
      //     } else {
      //       this.$message.error(res.message);
      //     }
      //   });

      if (!isAllow) {
        api
          .oneKeyPrint({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: null,
            secondTypeId: null,
            keyword: null,
            commodityId: this.commodityId,
            commodityMetering: this.commodityMetering,
          })
          .then((res) => {
            console.log(res);
            this.dialogVisible1 = true;
            this.num = res.data.length;
            this.printData = res.data;
            this.template = res.data2;
          });
      } else {
        if (this.num == 0) {
          this.$message.error("暂无可分拣商品");
          return;
        }
        api
          .sortingAllGoods({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: null,
            secondTypeId: null,
            keyword: null,
            commodityId: this.commodityId,
            commodityMetering: this.commodityMetering,
          })
          .then((res) => {
            if (res.state == 200) {
              this.$message({ type: "success", message: "分拣成功" });
              this.selectSortingList();
              this.print();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    danweidj(e, e1) {
      this.danweiIndex = e;
      this.commodityMetering = e1;
      console.log(this.commodityMetering);
      this.select3();
    },
    btsz() {
      this.drawer1 = true;
    },
  },
  mounted() {
    this.danweiData = [];
    // this.shipmentsDate = this.getDay(-1, "-");
    this.fj(0);
    console.log(this.$route.query.commodityId);
    console.log(this.$route.query.shipmentsDate);
    console.log(this.$route.query.commodityName);
    console.log(this.$route.query.clientName);
    this.clientId = this.$route.query.clientId;
    this.shipmentsDate = this.$route.query.shipmentsDate;
    this.commodityName = this.$route.query.commodityName;
    this.clientName1 = this.$route.query.clientName;
    this.state = this.$route.query.state;
    this.selectSortingList();
  },
};
</script>

<style scoped>
.a {
  width: 100%;
  height: calc(10vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: white;
}
.c {
  width: 15%;
  height: calc(95vh);
  background-color: rgb(39, 37, 37);
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: white;
}
::v-deep .el-drawer__header {
  display: none;
}
</style>
