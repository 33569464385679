import axios from "../../kit/axios_config";
import qs from "qs";

export default {
  // 查询单位
  getUnits(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityMetering", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  //一键分拣
  sortingAllGoods(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/onePieceSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  //商品分拣
  goodsSorting(data) {
    return new Promise((resolve) => {
      axios
        .get("/commoditySorting/sortingCommodity", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 打印模板
  printTemplate(type, orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/print/printSortingNo", {
          params: {
            type,
            orderCommodityId,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 一键分拣打印数据
  oneKeyPrint(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/printOnePieceSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 打印
  print(
    identity,
    shipmentsDate,
    firstTypeId,
    secondTypeId,
    commodityMetering,
    commodityId,
    orderCommodityId,
    num
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectPrintInfo", {
          params: {
            identity,
            shipmentsDate,
            firstTypeId,
            secondTypeId,
            commodityMetering,
            commodityId,
            orderCommodityId,
            num,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击商品分拣查询接口
  select1(
    identity,
    firstTypeId,
    secondTypeId,
    commodityName,
    shipmentsDate,
    sortingState,
    isRough,
    deliveryTimeId,
    warehouseId,
    lineId,
    providerId,
    content
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/select", {
          params: {
            identity,
            firstTypeId,
            secondTypeId,
            commodityName,
            shipmentsDate,
            sortingState,
            isRough,
            deliveryTimeId,
            warehouseId,
            lineId,
            providerId,
            content,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 获取分拣商品
  getSortingGoods(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityList", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 进入分拣详情
  getSortingGoodsDetail(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodity", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击分类查看商品列表接口
  select2(
    identity,
    firstTypeId,
    secondTypeId,
    commodityName,
    shipmentsDate,
    sortingState,
    isRough,
    deliveryTimeId,
    warehouseId,
    lineId,
    providerId,
    content
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/select", {
          params: {
            identity,
            firstTypeId,
            secondTypeId,
            commodityName,
            shipmentsDate,
            sortingState,
            isRough,
            deliveryTimeId,
            warehouseId,
            lineId,
            providerId,
            content,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // select2(list) {
  //     return new Promise(resolve => {
  //         axios.post("/sortByCommodity/select", list, {
  //             headers: {
  //                 'Content-Type': 'application/json;charset=utf-8'
  //             }
  //         }).then(res => {
  //             resolve(res.data)
  //         })
  //     })
  // },
  // 点击商品查询分拣列表接口
  select3(
    identity,
    commodityId,
    commodityMetering,
    shipmentsDate,
    clientName,
    sort,
    content
  ) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/select", {
          params: {
            identity,
            commodityId,
            commodityMetering,
            shipmentsDate,
            clientName,
            sort,
            content,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 根据商品ID查询商品名称接口
  selectCommodityMetering(commodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityMetering", {
          params: { commodityId },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击操作分拣商品接口查询接口
  viewDetails(orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/viewDetails", { params: { orderCommodityId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 重置商品分拣接口
  resetSorting(orderCommodityId, orderId, commodityId, sortingUnits) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/resetSorting", {
          params: { orderCommodityId, orderId, commodityId, sortingUnits },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 标记缺货接口
  signOutStock(orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/signOutStock", { params: { orderCommodityId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 多次分拣接口
  manyTimesSorting(orderCommodityId, sortingRecord, thisNum, orderNum,type) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/manyTimesSorting", {
          params: {
            orderCommodityId,
            sortingRecord,
            thisNum,
            orderNum,
            type
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 分拣商品接口
  sortingCommodity(orderCommodityId, sortingUnits, orderNum, practicalNum) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/sortingCommodity", {
          params: {
            orderCommodityId,
            sortingUnits,
            orderNum,
            practicalNum,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询送货时间接口
  selectDeliveryTime() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryTime").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询仓库接口
  selectWarehouse() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectWarehouse").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询线路接口
  selectDeliveryLine() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryLine").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询供应商接口
  queryProvider() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/queryProvider").then((res) => {
        resolve(res.data);
      });
    });
  },
};
