import { getLodop } from "./lodop"; //这块就是引用的上面一大坨暴露出来的方法喽

const PrintAccount = (template, data, data2) => {
  console.log(data);
  console.log(data2);
  var model = JSON.parse(template.printContent);
  // 调用打印对象
  console.log(model);

  // return
  LODOP = getLodop();
  LODOP.PRINT_INITA(
    template.pageTop,
    template.pageLeft,
    template.pageWidth + "mm",
    template.pageHeight + "mm",
    ""
  );
  // 打印页面配置
  LODOP.SET_PRINT_PAGESIZE(
    model.direction,
    template.pageWidth + "mm",
    template.pageHeight + "mm",
    ""
  ); //设置打印 方向 1，2，3   宽，高，纸张类型名：a4
  // if (template.printer) LODOP.SET_PRINTER_INDEX(template.printer); //指定打印机

  //   LODOP.ADD_PRINT_LINE(); //打印线条 top1 left1 top2 left2 lineStyle:0实现，1破折现 2点线 3点划线 4双点划线  lineWidth线宽
  //  LODOP.ADD_PRINT_BARCODE() //top left width height codetype(128Auto) codeValue(条码值)  生成条码
  let tableStr;
  let tdName = "";
  let trStr = "";
  model.tableList.forEach((val) => {
    tdName += `<td style="letter-spacing:${val.style.letterSpacing};text-align:${val.style.textAlign};text-decoration:${val.style.textDecoration};width:${val.width};font-size:${val.style.fontSize};font-weight:${val.style.fontWeight};font-style:${val.style.fontStyle};">${val.innerText}</td>`;
  });
  tdName ? (trStr = `<tr>${tdName}</tr>`) : "";
  data.forEach((val) => {
    tdName = "";
    model.tableIndex.forEach((item) => {
      if (val[item] === "" || val[item] === null) val[item] = "";
      tdName += `<td>${val[item]}</td>`;
    });
    trStr += `<tr>${tdName}</tr>`;
  });
  tableStr =
    "<table border='1' style='width:100%;border:solid 1px black;border-collapse:collapse'>" +
    trStr +
    "</table>";
  LODOP.ADD_PRINT_TABLE(
    model.fixDiv.offsetTop,
    model.fixDiv.offsetLeft,
    model.fixDiv.width,
    "70%",
    // (data.length + 1) * model.tableList[0].height.split("mm")[0] * 3,
    tableStr
  );
  // 显示收货人签名
  if (template.isShowSign == 1) {
    LODOP.SET_PRINT_STYLE("FontName", model.divList[0].style.fontFamily); //设置打印样式 style 对应
    LODOP.SET_PRINT_STYLE("FontSize", model.divList[0].style.fontSize); //设置打印样式 style 对应
    LODOP.SET_PRINT_STYLE("Bold", model.divList[0].style.fontWeight); //设置打印样式 style 对应
    LODOP.SET_PRINT_STYLE("Italic", model.divList[0].style.fontStyle); //设置打印样式 style 对应
    LODOP.SET_PRINT_STYLE("Underline", model.divList[0].style.textDecoration); //设置打印样式 style 对应
    LODOP.SET_PRINT_STYLE("Alignment", model.divList[0].style.textAlign); //设置打印样式 style 对应
    LODOP.ADD_PRINT_TEXT(
      "90%",
      "100px",
      "200px",
      "BottomMargin:5mm",
      "收货人："
    ); // 字体打印   top  left width height content
    LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
  }
  // 显示页码
  if (template.isShowPageNum == 1) {
    LODOP.ADD_PRINT_HTM(
      "96%",
      "50%",
      "200px",
      "BottomMargin:0mm",
      `<p format="#"  align="center"><b  tdata="pageNO">第<font color="#0000FF">#</font>页</b><b tdata="pageCount">总<font color="#0000FF">##</font>页</b></p> `
    ); // 字体打印   top  left width height content
    LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
  }
  model.divList.forEach((val, i) => {
    if (val.offsetTop > model.fixDiv.offsetTop) {
      if (val.text) {
        //打印条码
        LODOP.ADD_PRINT_BARCODE(
          // (data.length + 1) * model.tableList[0].height.split("px")[0] +
          (data.length + 1) *
            (model.tableList.length > 10
              ? 30
              : model.tableList.length > 6
              ? 28
              : 24) +
            val.offsetTop +
            template.pageTop +
            20,
          val.offsetLeft + template.pageLeft,
          val.width,
          val.height,
          "128Auto",
          val.text
        );
        LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
      } else {
        if (model.divIndex[i] == "source_code") {
          LODOP.SET_PRINT_STYLE("FontName", val.style.fontFamily); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("FontSize", val.style.fontSize); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Bold", val.style.fontWeight); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Italic", val.style.fontStyle); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Underline", val.style.textDecoration); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Alignment", val.style.textAlign); //设置打印样式 style 对应
          LODOP.ADD_PRINT_BARCODE(
            (data.length + 1) *
              (model.tableList.length > 10
                ? 30
                : model.tableList.length > 6
                ? 28
                : 24) +
              val.offsetTop +
              template.pageTop +
              20,
            val.offsetLeft + template.pageLeft,
            val.width,
            val.height,
            "QRCode",
            data2[model.divIndex[i]]
          );
        } else {
          // 判断对应的字段是否为空不为空则替换为数据
          if (model.divIndex[i] != "") val.innerText = data2[model.divIndex[i]];
          LODOP.SET_PRINT_STYLE("FontName", val.style.fontFamily); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("FontSize", val.style.fontSize); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Bold", val.style.fontWeight); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Italic", val.style.fontStyle); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Underline", val.style.textDecoration); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Alignment", val.style.textAlign); //设置打印样式 style 对应
          console.log(val.width);
          LODOP.ADD_PRINT_TEXT(
            // (data.length + 1) * model.tableList[0].height.split("px")[0] +
            (data.length + 1) *
              (model.tableList.length > 10
                ? 30
                : model.tableList.length > 6
                ? 28
                : 24) +
              val.offsetTop +
              template.pageTop +
              20,
            val.offsetLeft + template.pageLeft,
            val.width,
            val.height,
            val.innerText
          ); // 字体打印   top  left width height content
        }
      }
    } else {
      if (val.text) {
        //打印条码
        LODOP.ADD_PRINT_BARCODE(
          val.offsetTop + template.pageTop,
          val.offsetLeft + template.pageLeft,
          val.width,
          val.height,
          "128Auto",
          val.text
        );
        LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
      } else {
        console.log(model.divIndex[i])
        if (model.divIndex[i] == "source_code") {
          console.log(data2[model.divIndex[i]]+"?id="+data2.orderCommodityId)
          LODOP.SET_PRINT_STYLE("FontName", val.style.fontFamily); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("FontSize", val.style.fontSize); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Bold", val.style.fontWeight); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Italic", val.style.fontStyle); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Underline", val.style.textDecoration); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Alignment", val.style.textAlign); //设置打印样式 style 对应
          LODOP.ADD_PRINT_BARCODE(
            val.offsetTop + template.pageTop,
            val.offsetLeft + template.pageLeft,
            val.width,
            val.height,
            "QRCode",
            data2[model.divIndex[i]]+"?id="+data2.orderCommodityId
          );
          console.log(val.width,val.height)
        } else {
          // 判断对应的字段是否为空不为空则替换为数据
          if (model.divIndex[i] != "") val.innerText = data2[model.divIndex[i]];
          LODOP.SET_PRINT_STYLE("FontName", val.style.fontFamily); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("FontSize", val.style.fontSize); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Bold", val.style.fontWeight); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Italic", val.style.fontStyle); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Underline", val.style.textDecoration); //设置打印样式 style 对应
          LODOP.SET_PRINT_STYLE("Alignment", val.style.textAlign); //设置打印样式 style 对应
          console.log(val.width);
          LODOP.ADD_PRINT_TEXT(
            val.offsetTop + template.pageTop,
            val.offsetLeft + template.pageLeft,
            val.width,
            val.height,
            val.innerText
          ); // 字体打印   top  left width height content
        }
      }
    }
  });

  //   LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Width");
  // LODOP.PREVIEW();
  LODOP.PRINT();
};

export { PrintAccount };
