<template>
  <!-- 按商品分拣 -->
  <div style="background-color:#ddd;height:100vh">
    <div class="a">
      <div style="width:7%;display:flex">
        <div
          style="display:flex;alignItems:center;margin:auto"
          @click="$router.push('index')"
        >
          <img src="../assets/back.png" alt style="width:15px;height:15px" />
          <div style="color:#000;fontSize:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:70%;align-items: center;">
        <el-input
          placeholder="请输入客户名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="width:600px;margin:auto"
          @input="select(search)"
        ></el-input>
        <div style="width: 100px;color: #fff;display: flex;">
          <div
            style="width: 20px;height: 20px;background-color:rgb(197,74,74);"
          ></div>
          待分拣
        </div>
        <div style="width: 100px;color: #fff;display: flex;">
          <div
            style="width: 20px;height: 20px;background-color:rgb(188,188,0);"
          ></div>
          部分分拣
        </div>
        <div style="width: 100px;color: #fff;display: flex;">
          <div
            style="width: 20px;height: 20px;background-color:rgb(66,180,133);"
          ></div>
          已分拣
        </div>
      </div>
      <div
        style="display:flex;width:30%;margin-left:10px;flex-flow: row-reverse;fontSize:15px"
      >
        <!-- <div class="b" style="backgroundColor:rgb(197,74,74);fontSize:15px">
          <div class="yjfj" @click="yjfj()" style="fontSize:20px">一键分拣</div>
        </div> -->
        <div class="b" style="backgroundColor:rgb(66,151,178);fontSize:15px">
          <div class="sx" @click="sx" style="fontSize:20px">筛选</div>
        </div>
        <div class="b" style="backgroundColor:rgb(66,180,133);fontSize:15px">
          <div class="yjdy" @click="yjdy()" style="fontSize:20px">一键打印</div>
        </div>
      </div>
    </div>
    <div style="display:flex;flex-direciton:row;">
      <div class="c" v-if="searchFlag">
        <div
          style="height: calc(8vh);display:flex;border-bottom: 1px solid grey;color:white"
          v-for="(item, index) in radioData"
          :key="index"
        >
          <div style="margin:auto">
            <el-radio-group v-model="radio" :key="index" @change="radioChange">
              <el-radio :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div style="width:85%;background-color: #ddd;">
        <div
          style="height: calc(4vh);color:rgb(197,74,74);fontSize:20px;fontWeight:bold;margin-left:10px"
        >
          <span>发货日期：</span>
          <span>{{ shipmentsDate }}</span>
        </div>
        <div style="height:calc(91vh);overflow: auto;">
          <div v-for="(item, index) in searchData" :key="index">
            <div
              @click="
                shouqiweishouqi(item.firstTypeId, item.secondTypeId, index)
              "
              style="height: calc(6vh);fontSize:20px;margin:10px;display:flex;flex-direction:row;justify-content:space-between;alignItems:center;border-bottom:1px solid grey"
            >
              <div style="fontWeight:bold">
                {{ item.firstTypeName + " - " + item.secondTypeName }}
              </div>
              <div
                style="width:100px;height:33px;backgroundColor:rgb(66,180,133);display:flex;border-radius:50px;"
              >
                <div style="margin:auto;color:#000" v-if="radioIndex == index">
                  - 收起
                </div>
                <div style="margin:auto;color:#000" v-else>+ 展开</div>
              </div>
            </div>
            <div
              v-if="radioIndex == index"
              style="display:flex;flex-wrap: wrap;"
            >
              <div
                v-for="(item1, index1) in searchData1"
                :key="index1"
                style="margin:10px"
              >
                <div
                  @click="
                    goToAspfjDetail(
                      item1.commodityId,
                      shipmentsDate,
                      item1.commodityName,
                      item1.numberSorted,
                      item1.unselectedNumber
                    )
                  "
                >
                  <div style="width:130px;height:90px;border:1px solid grey">
                    <div
                      style="width:40%;height:30%;backgroundColor:rgb(66,180,133);display:flex;"
                      v-if="item1.numberSorted == item1.unselectedNumber"
                    >
                      <div style="margin:auto;color:#000;fontSize:10px">
                        已完成
                      </div>
                    </div>
                    <div
                      style="width:40%;height:30%;backgroundColor:rgb(197,74,74);display:flex;"
                      v-if="item1.numberSorted == 0"
                    >
                      <div style="margin:auto;color:#000;fontSize:10px">
                        未分拣
                      </div>
                    </div>
                    <div
                      style="width:40%;height:30%;backgroundColor:rgb(188,188,0);display:flex;"
                      v-if="
                        item1.numberSorted * 1 > 0 &&
                          item1.numberSorted * 1 < item1.unselectedNumber * 1
                      "
                    >
                      <div style="margin:auto;color:#000;fontSize:10px">
                        部分分拣
                      </div>
                    </div>
                    <div style="width:100%;height:40%;display:flex;">
                      <div style="margin:auto;fontSize:12px">
                        {{ item1.commodityName }}
                      </div>
                    </div>
                    <div
                      style="width:100%;height:30%;backgroundColor:rgb(66,180,133);display:flex;"
                      v-if="item1.numberSorted == item1.unselectedNumber"
                    >
                      <div style="margin:auto;color:#000;fontSize:15px">
                        分拣进度:{{ item1.numberSorted }}/{{
                          item1.unselectedNumber
                        }}
                      </div>
                    </div>
                    <div
                      style="width:100%;height:30%;backgroundColor:rgb(197,74,74);display:flex;"
                      v-if="item1.numberSorted == 0"
                    >
                      <div style="margin:auto;color:#000;fontSize:15px">
                        分拣进度:{{ item1.numberSorted }}/{{
                          item1.unselectedNumber
                        }}
                      </div>
                    </div>
                    <div
                      style="width:100%;height:30%;backgroundColor:rgb(188,188,0);display:flex;"
                      v-if="
                        item1.numberSorted * 1 > 0 &&
                          item1.numberSorted * 1 < item1.unselectedNumber * 1
                      "
                    >
                      <div style="margin:auto;color:#000;fontSize:15px">
                        分拣进度:{{ item1.numberSorted }}/{{
                          item1.unselectedNumber
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span style="fontSize:20px">是否确定一键打印{{ num }}个标签</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="print()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div>
      <el-drawer
        title="筛选"
        :visible.sync="drawer"
        :direction="direction"
        size="70%"
      >
        <div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">发货日期</div>
            <div style="display:flex;flex-direction:row">
              <div
                style="width:120px;height:40px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in dateData"
                :key="index"
                @click="fhrqdj(index)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="dateIndex == index"
                >
                  {{ item }}
                </div>
                <div style="margin:auto" v-else>{{ item }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="dateIndex == index"
                />
              </div>
              <div style="width:120px;height:60px;margin:10px">
                <el-date-picker
                  v-model="shipmentsDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div style="display:flex;flex-direction:row">
            <div style="display:flex">
              <div style="margin:10px 0 0 10px;fontWeight:bold">分拣状态</div>
              <div style="display:flex;flex-direction:row">
                <el-select v-model="fjztValue" placeholder="请选择">
                  <el-option
                    v-for="item in fjztData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item, index) in fjztData"
                  :key="index"
                  @click="fjztdj(index)"
                >
                  <div
                    style="margin:auto;color:rgb(66,151,178)"
                    v-if="fjztIndex == index"
                  >
                    {{ item }}
                  </div>
                  <div style="margin:auto" v-else>{{ item }}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="fjztIndex == index"
                  />
                </div> -->
              </div>
            </div>
            <div style="display:flex">
              <div style="margin:10px 0 0 10px;fontWeight:bold">是否标品</div>
              <div style="display:flex;flex-direction:row">
                <el-select v-model="sfbpValue" placeholder="请选择">
                  <el-option
                    v-for="item in sfbpData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- <div
                  style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                  v-for="(item, index) in sfbpData"
                  :key="index"
                  @click="sfbpdj(index)"
                >
                  <div
                    style="margin:auto;color:rgb(66,151,178)"
                    v-if="sfbpIndex == index"
                  >
                    {{ item }}
                  </div>
                  <div style="margin:auto" v-else>{{ item }}</div>
                  <img
                    src="../assets/gou.png"
                    alt
                    style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                    v-if="sfbpIndex == index"
                  />
                </div> -->
              </div>
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">更多筛选条件</div>
            <div style="margin:10px">
              <el-select v-model="shsjValue" placeholder="请选择收货时间">
                <el-option
                  v-for="item in shsjData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-model="warehouseValue"
                placeholder="请选择仓库"
                style="margin-left:10px"
              >
                <el-option
                  v-for="item in warehouseData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- <el-select v-model="value" placeholder="请选择" style="margin-left:10px">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>-->
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">请选择路线</div>
            <div style="marginLeft:10px;marginTop:10px">
              <el-select v-model="path" placeholder="请选择">
                <el-option
                  v-for="item in lxData"
                  :key="item.lineId"
                  :label="item.lineName"
                  :value="item.lineId"
                >
                </el-option>
              </el-select>
              <!-- <div
                style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in lxData"
                :key="index"
                @click="lxdj(index, item.lineId)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="lxIndex.indexOf(index) != -1"
                >
                  {{ item.lineName }}
                </div>
                <div style="margin:auto" v-else>{{ item.lineName }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                  v-if="lxIndex.indexOf(index) != -1"
                />
              </div> -->
            </div>
          </div>
          <div>
            <div style="margin:10px 0 0 10px;fontWeight:bold">请选择供应商</div>
            <div style="marginLeft:10px;marginTop:10px">
              <el-select v-model="providerId" placeholder="请选择">
                <el-option
                  v-for="item in gysData"
                  :key="item.providerId"
                  :label="item.providerName"
                  :value="item.providerId"
                >
                </el-option>
              </el-select>
              <!-- <div
                style="width:200px;height:100px;border:1px solid grey;display:flex;position:relative;margin:10px"
                v-for="(item, index) in gysData"
                :key="index"
                @click="gysdj(index, item.providerId)"
              >
                <div
                  style="margin:auto;color:rgb(66,151,178)"
                  v-if="gysIndex == index"
                >
                  {{ item.providerName }}
                </div>
                <div style="margin:auto" v-else>{{ item.providerName }}</div>
                <img
                  src="../assets/gou.png"
                  alt
                  style="width:20px;height:20px;position:absolute;bottom:0;right:0"
                  v-if="gysIndex == index"
                />
              </div> -->
            </div>
          </div>
          <div style="display:flex;flex-direcion:row;justify-content:flex-end;">
            <div
              style="width:250px;height:60px;display:flex;border:1px solid black;margin-right:20px"
              @click="() => (drawer = false)"
            >
              <div style="margin:auto">取消</div>
            </div>
            <div
              @click="
                sure();
                radioIndex = null;
              "
              style="width:250px;height:60px;display:flex;background-color:rgb(66,151,178);margin-right:20px"
            >
              <div style="margin:auto;color:#000">确定</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div>
      <el-dialog title="提示" :visible.sync="dialogVisible1" width="30%">
        <span
          >将有
          {{ num }}
          个标签被打印（注意：此功能会分拣并打印所有未分拣的商品），确定全部打印？</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="yjfj(1)">是</el-button>
          <el-button @click="dialogVisible1 = false">否</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
  <!-- 按商品分拣 -->
</template>

<script>
import { PrintAccount } from "../print/doPrint";
import api from "../apis/akhfj/akhfj";
export default {
  data() {
    return {
      value: "",
      options: [],
      drawer: false,
      direction: "ttb",
      search: "",
      radio: "",
      radioData: [{ label: "全部", value: "" }],
      radioIndex: 999,
      dialogVisible: false,
      dateData: ["昨日", "今日", "明日"],
      dateIndex: 1,
      shipmentsDate: "",
      fjztData: [
        { label: "全部", value: "" },
        { label: "已分拣", value: 1 },
        { label: "未分拣", value: 0 },
      ],
      fjztIndex: 0,
      fjztValue: "",
      sfbpData: [
        { label: "全部", value: "" },
        { label: "标品", value: 1 },
        { label: "未标品", value: 0 },
      ],
      // sfbpData: ["全部", "标品", "未标品"],
      sfbpIndex: 0,
      sfbpValue: "",
      lxData: [{ lineName: "全部", lineId: "" }],
      lxIndex: [],
      lxValue: [],
      lineId: "",
      gysData: [{ providerName: "全部供应商", providerId: "" }],
      gysIndex: 0,
      gysValue: "",
      dialogVisible1: false,
      warehouseData: [],
      warehouseValue: "",
      shsjData: [],
      shsjValue: "",
      shipmentsDate: "",
      searchData: [],
      firstTypeId: "",
      secondTypeId: "",
      searchData1: [],
      searchFlag: true,
      num: 0,
      printData: [],
      template: {},
      path: "",
      providerId: "",
    };
  },
  beforeRouteLeave(to, from, next) {
    // 离开当前路由页面时调用
    this.drawer = false;
    next();
  },
  beforeRouteEnter(to, from, next) {
    // 注意，在路由进入之前，组件实例还未渲染，所以无法获取this实例，只能通过vm来访问组件实例
    next((vm) => {
      // vm.firstTypeId = sessionStorage.getItem("firstTypeId");
      // vm.secondTypeId = sessionStorage.getItem("secondTypeId");
      // vm.radioIndex = sessionStorage.getItem("radioIndex");
      api
        .select2(
          localStorage.identity,
          sessionStorage.getItem("firstTypeId"),
          sessionStorage.getItem("secondTypeId"),
          vm.search,
          vm.shipmentsDate,
          vm.fjztValue,
          vm.sfbpValue,
          vm.shsjValue,
          vm.warehouseValue,
          vm.path,
          vm.providerId,
          2
        )
        .then((res) => {
          console.log(res);
          vm.searchData1 = res.data;
        });
      // vm.select2();
      // vm.radio = sessionStorage.getItem("radio") * 1;
      // vm.radioData = [{ label: "全部", value: "" }];
      // vm.select1();
      // sessionStorage.removeItem("firstTypeId");
      // sessionStorage.removeItem("secondTypeId");
      // sessionStorage.removeItem("radioIndex");
      // sessionStorage.removeItem("radio");
    });
  },
  methods: {
    print() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.printData.forEach((val) => {
        PrintAccount(this.template, [], val);
      });
    },
    sure() {
      this.radioData = [{ label: "全部", value: "" }];
      this.firstTypeId = null;
      this.secondTypeId = null;
      this.radio = "";
      this.select1();
      this.drawer = false;
    },
    radioChange(e) {
      console.log(this.radio);
      sessionStorage.setItem("radio", this.radio);
      console.log(e);
      this.firstTypeId = e;
      this.secondTypeId = "";
      this.radioIndex = 999;
      this.select1(e);
      // this.select2();
    },
    select(e) {
      console.log(e);
      this.select1();
      if (e != "") {
        this.searchFlag = false;
      } else {
        this.searchFlag = true;
      }
    },
    // 点击商品分拣查询接口
    select1(e) {
      console.log(this.firstTypeId, this.secondTypeId);
      api
        .select1(
          localStorage.identity,
          this.firstTypeId,
          this.secondTypeId,
          this.search,
          this.shipmentsDate,
          this.fjztValue,
          this.sfbpValue,
          this.shsjValue,
          this.warehouseValue,
          this.path,
          this.providerId,
          1
        )
        .then((res) => {
          console.log(res);
          console.log(res.data.ctList);
          // this.radioData = [{ label: "全部", value: "" }];
          var a = [""];
          res.data.ctfList.forEach((item, index) => {
            a.push(item.firstTypeId);
          });
          res.data.ctfList.forEach((item, index) => {
            // a.push(item.firstTypeId);
            if (a.indexOf(e) == -1) {
              this.radioData.push({
                value: item.firstTypeId,
                label: item.firstTypeName,
              });
            }
          });
          res.data.ctList.sort((a, b) => a.firstTypeId - b.firstTypeId);
          console.log(res.data.ctList);
          this.searchData = res.data.ctList;
        });
    },
    select2() {
      console.log(this.sfbpValue, this.fjztValue);
      api
        .select2(
          localStorage.identity,
          this.firstTypeId,
          this.secondTypeId,
          this.search,
          this.shipmentsDate,
          this.fjztValue,
          this.sfbpValue,
          this.shsjValue,
          this.warehouseValue,
          this.path,
          this.providerId,
          2
        )
        .then((res) => {
          console.log(res);
          this.searchData1 = res.data;
        });
    },
    // 查询送货时间接口
    selectDeliveryTime() {
      api.selectDeliveryTime().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.shsjData.push({
            value: item.deliveryTimeId,
            label: item.deliveryTime,
          });
        });
      });
    },
    // 查询供应商接口
    queryProvider() {
      api.queryProvider().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.gysData.push(item);
        });
      });
    },
    // 查询线路接口
    selectDeliveryLine() {
      api.selectDeliveryLine().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.lxData.push(item);
        });
      });
    },
    // 查询仓库接口
    selectWarehouse() {
      api.selectWarehouse().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.warehouseData.push({
            value: item.warehouseId,
            label: item.warehouseName,
          });
        });
      });
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    fhrqdj(e) {
      console.log(e);
      this.dateIndex = e;
      if (e == 0) {
        this.shipmentsDate = this.getDay(0, "-");
      }
      if (e == 1) {
        this.shipmentsDate = this.getDay(1, "-");
      }
      if (e == 2) {
        this.shipmentsDate = this.getDay(2, "-");
      }
    },
    fjztdj(e) {
      console.log(e);
      this.fjztIndex = e;
      if (e == 0) {
        this.fjztValue = "";
      }
      if (e == 1) {
        this.fjztValue = 1;
      }
      if (e == 2) {
        this.fjztValue = 0;
      }
      console.log(this.fjztValue);
    },
    sfbpdj(e) {
      this.sfbpIndex = e;
      if (e == 0) {
        this.sfbpValue = "";
      }
      if (e == 1) {
        this.sfbpValue = 1;
      }
      if (e == 2) {
        this.sfbpValue = 0;
      }
      console.log(this.sfbpValue);
    },
    lxdj(e, e1) {
      // console.log(e)
      if (this.lxIndex.indexOf(e) == -1) {
        this.lxIndex.push(e);
        this.lxValue.push(e1);
        this.lineId = this.lxValue.join(",");
      } else {
        this.lxIndex.splice(this.lxIndex.indexOf(e), 1);
        this.lxValue.splice(this.lxValue.indexOf(e1), 1);
        this.lineId = this.lxValue.join(",");
      }
      console.log(this.lxIndex);
    },
    gysdj(e, e1) {
      this.gysIndex = e;
      this.gysValue = e1;
    },
    shouqiweishouqi(e1, e2, e3) {
      console.log(e1);
      console.log(e2);
      console.log(e3);
      sessionStorage.setItem("radioIndex", e3);
      sessionStorage.setItem("firstTypeId", e1);
      sessionStorage.setItem("secondTypeId", e2);
      if (this.radioIndex == e3) {
        this.radioIndex = 999;
      } else {
        this.radioIndex = e3;
        this.firstTypeId = e1;
        this.secondTypeId = e2;
        this.select2();
      }
    },
    yjdy(item) {
      api
        .print(
          localStorage.identity,
          this.shipmentsDate,
          this.firstTypeId,
          this.secondTypeId
        )
        .then((res) => {
          if (!item) this.dialogVisible = true;
          this.num = res.data.length;
          this.printData = res.data;
          this.template = res.data2;
          console.log(res);
          // if (!item) {
          //   this.$message({ type: "success", message: "打印成功" });
          //   res.data.forEach((val) => {
          //     PrintAccount(res.data2, [], val);
          //   });
          // }
        });
    },
    sx() {
      this.drawer = true;
    },
    yjfj(isAllow) {
      console.log(this.shipmentsDate);
      // this.dialogVisible1 = true;
      // this.yjdy(1);
      if (!isAllow) {
        api
          .oneKeyPrint({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: this.firstTypeId,
            secondTypeId: null,
            keyword: this.search,
            commodityId: null,
            commodityMetering: null,
          })
          .then((res) => {
            console.log(res);
            this.dialogVisible1 = true;
            this.num = res.data.length;
            this.printData = res.data;
            this.template = res.data2;
          });
      } else {
        if (this.num == 0) {
          this.$message.error("暂无可分拣商品");
          return;
        }
        api
          .sortingAllGoods({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: this.firstTypeId,
            secondTypeId: null,
            keyword: this.search,
            commodityId: null,
            commodityMetering: null,
          })
          .then((res) => {
            if (res.state == 200) {
              this.$message({ type: "success", message: "分拣成功" });
              this.print();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    goToAspfjDetail(e1, e2, e3, e4, e5) {
      var state = 0;
      if (e4 == e5) {
        state = 1;
      } else {
        state = 0;
      }
      var obj = {
        commodityId: e1,
        shipmentsDate: e2,
        commodityName: e3,
        state: state,
        shipmentsDate: this.shipmentsDate,
      };
      this.$router.push({
        path: "/aspfjDetail",
        query: obj,
      });
    },
  },
  mounted() {
    this.shipmentsDate = localStorage.shipmentsDate;
    // this.shipmentsDate = this.getDay(0, "-");
    console.log(localStorage.identity);
    this.shipmentsDate = localStorage.shipmentsDate;
    this.selectWarehouse();
    this.selectDeliveryLine();
    this.queryProvider();
    this.selectDeliveryTime();
    this.select1();
    this.select2();
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.a {
  width: 100%;
  height: calc(10vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: #000;
}
.c {
  width: 15%;
  height: calc(95vh);
  background-color: rgb(39, 37, 37);
  overflow: auto;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: #000;
}
::v-deep .el-drawer__header {
  display: none;
}
>>> .el-radio__label {
  color: wheat;
}
</style>
